<script setup>
import { ref, onMounted } from 'vue';
import { RouterView, useRouter } from 'vue-router'
import Spinner from '@/components/partial/Spinner.vue';
import AdminFooter from '@/components/FooterBase.vue';
import AdminNavAside from '@/components/NavAside.vue';


const loading = ref(false);
const router = useRouter();

onMounted(() => {
  router.beforeEach(() => {
    loading.value = true;
  });

  router.afterEach(() => {
    loading.value = false;
  });

  router.onError(() => {
    loading.value = false; // Make sure to handle errors
  });
});

</script>


<template>
    <AdminNavAside /> 
    <div class="admin_layout">
        <Spinner v-if="loading" />
        <router-view />
        <AdminFooter />
    </div>
</template>

<style scoped lang="scss">
//
.admin_layout {
  overflow: hidden;
  padding-left: 200px;
  background-color: #f1f5ff70;
}
</style>