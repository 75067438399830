import { createRouter, createWebHistory } from "vue-router";
import { useUserStore } from "@/stores/user";
import { nextTick } from "vue";

import ActionView from "../views/action/ActionView.vue";
import addAction from "../views/action/AddAction.vue";
import editGestionAction from "../views/action/EditAction.vue";
import ListArticle from "../views/news/ListeNews.vue";

import AdminLayout from "@/layouts/AdminLayout.vue";


const routes = [
    /**************** LAYOUT ADMIN ****************/
    {
      path: "/",
      name: "connexion",
      component: () => import("../views/LoginRegisterView.vue"),
      meta: {
        title: "Connexion",
      },
    },

    {
      path: '/admin',
      component: AdminLayout,
      children: [
        {
          path: "/administration/user",
          name: "adminUser",
          component: () => import("../views/administration/UserView.vue"),
          meta: {
            title: "Utilisateurs",
            requiresAuth: true,
          },
        },

        {
          path: "/administration/dashboard",
          name: "adminDashboard",
          component: () => import("../views/administration/dashboard/Views.vue"),
          meta: {
            title: "Dashboard",
            requiresAuth: true,
          },
        },
    
        // PRODUCT
        {
          path: "/administration/product",
          name: "listProduct",
          component: () =>
            import("../views/administration/product/ListProduct.vue"),
          meta: {
            title: "Liste des Produits",
            requiresAuth: true,
          },
        },
    
        {
          path: "/administration/product/add",
          name: "addProduct",
          component: () => import("../views/administration/product/AddProduct.vue"),
          meta: {
            title: "Ajouter Produit",
            requiresAuth: true,
          },
        },
    
        {
          path: "/administration/product/edit/:slug",
          name: "editProduct",
          component: () =>
            import("../views/administration/product/EditProduct.vue"),
          meta: {
            title: "Editer Produit",
            requiresAuth: true,
          },
        },
    
        // CUSTOMER
        {
          path: "/administration/customer",
          name: "listCustomer",
          component: () =>
            import("../views/administration/customer/ListCustomer.vue"),
          meta: {
            title: "Clients",
            requiresAuth: true,
          },
        },
    
        {
          path: "/administration/customer/add",
          name: "addCustomer",
          component: () =>
            import("../views/administration/customer/AddCustomer.vue"),
          meta: {
            title: "Ajouter client",
            requiresAuth: true,
          },
        },
        {
          path: "/administration/customer/edit/:id",
          name: "editCustomer",
          component: () =>
            import("../views/administration/customer/EditCustomer.vue"),
          meta: {
            title: "Modification client",
            requiresAuth: true,
          },
        },
    
        /************** GESTION DES ACTIONS ********/
        {
          path: "/action",
          name: "ActionView",
          component: ActionView,
          meta: {
            title: "Liste des actions",
            requiresAuth: true,
          },
        },
    
        {
          path: "/action/add",
          name: "addAction",
          component: addAction,
          meta: {
            title: "Ajouter une actions",
            requiresAuth: true,
          },
        },
    
        {
          path: "/action/edit/:gestionAction_id",
          name: "editGestionAction",
          component: editGestionAction,
          meta: {
            title: "Editer une Action",
            requiresAuth: true,
          },
        },
    
        /**************** LOV ****************/
        {
          path: "/administration/lov",
          name: "lovIndex",
          component: () => import("../views/administration/ListLov.vue"),
          meta: {
            title: "Liste des valeurs",
            requiresAuth: true,
          },
        },
    
        /**************** AUDIT ****************/
        {
          path: "/audit",
          name: "audit",
          component: () =>
            import("../views/administration/AuditTrail/ListAudit.vue"),
          meta: {
            title: "audit",
            requiresAuth: true,
          },
        },
    
        /**************** FIN ADMIN ****************/
        /************************************/
    
        /**************** NEWS ****************/
    
        {
          path: "/news",
          name: "listNews",
          component: ListArticle,
          meta: {
            title: "Liste des actualités",
            requiresAuth: true,
          },
        },
    
        {
          path: "/news-disabled",
          name: "disabledNews",
          component: () => import("../views/news/DisabledNews.vue"),
          meta: {
            title: "Actualités désactivé",
            requiresAuth: true,
          },
        },
    
        {
          path: "/news/:slug",
          name: "showNews",
          component: () => import("../views/news/ShowNews.vue"),
          meta: {
            title: "actualités",
            requiresAuth: true,
          },
        },
    
        {
          path: "/news/add",
          name: "addNews",
          component: () => import("../views/news/AddNews.vue"),
          meta: {
            title: "Ajout actualité",
            requiresAuth: true,
          },
        },
    
        {
          path: "/news/edit/:slug",
          name: "editNews",
          component: () => import("../views/news/EditNews.vue"),
          meta: {
            title: "Modification actualité",
            requiresAuth: true,
          },
        },
    
        /**************** FIN NEWS ****************/
        /************************************/
    
        /**************** EXPORT ****************/
        {
          path: "/export",
          name: "export",
          component: () => import("../views/ExportView.vue"),
          meta: {
            title: "export",
            requiresAuth: true,
          },
        },
        /**************** FIN EXPORT ****************/
        /************************************/
    
        /**************** SCRAPING ****************/
        {
          path: "/scraping",
          name: "scraping",
          component: () => import("../views/scraping/Test.vue"),
          meta: {
            title: "scraping",
            requiresAuth: true,
          },
        },
    
        {
          path: "/dashboard",
          name: "dashboard",
          component: () => import("../views/DashboardView.vue"),
          meta: {
            title: "Mon Compte",
            requiresAuth: true,
          },
        },
      ]
    },

    
    
    /***** REPONSE DEPUIS EMAIL APRES INSCRIPTION *****/
    {
      path: "/success-email",
      name: "success-email",
      component: () => import("../views/SuccesEmailView.vue"),
      meta: {
        title: "E-mail verifié",
      },
    },
    {
      path: "/invalid-email",
      name: "invalid-email",
      component: () => import("../views/InvalidEmailView.vue"),
      meta: {
        title: "E-mail non valide",
      },
    },
    /**************** RESET PASSWORD ****************/
    {
      path: "/password-forget",
      name: "passForget",
      component: () => import("../views/PassForgetView.vue"),
      meta: {
        title: "Mot de passe oublié",
      },
    },
    {
      path: "/reset-password/:token",
      name: "resetPassword",
      component: () => import("../views/ResetPasswordView.vue"),
      meta: {
        title: "Nouveau mot de passe",
      },
    },
    {
      path: "/not-found",
      name: "NotFound",
      component: () => import("../views/NotFoundView.vue"),
      meta: {
        title: "Page introuvable",
      },
    },
  ]

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return new Promise((resolve) => {
      nextTick(() => {
        window.scrollTo(0, 0);
        resolve({ top: 0 });
      });
    });
  },
});


router.beforeEach((to, from, next) => {
  let documentTitle = to.meta.title;
  if (to.params.title) {
    documentTitle += " - " + to.params.title;
  }
  document.title = documentTitle;

  next();
});

router.beforeEach((to, from, next) => {
  // Vérifiez si la route précédente est marquée comme mise en cache et si la route actuelle est la même que la route précédente
  if (from.meta.cache && to.path === from.path) {
    // Empêche Vue.js de détruire et recréer le composant lorsque l'utilisateur revient à cette page
    next(false);
  } else {
    next();
  }
});

router.beforeEach((to, from, next) => {
  const userStore = useUserStore();

  if (!userStore.isLoggedIn && to.meta.requiresAuth) {
    // Si l'utilisateur n'est pas connecté et que la page nécessite une authentification
    next({ name: 'connexion' });
  } else if (userStore.isLoggedIn && to.name === 'connexion') {
    // Si l'utilisateur est déjà connecté et tente d'accéder à la page de connexion
    next({ name: 'dashboard' });
  } else {
    // Pour toutes les autres situations
    next();
  }
});

export default router;
