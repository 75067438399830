import { defineStore } from "pinia";
import axios from "axios";
import Swal from "sweetalert2";
import { jwtDecode } from "jwt-decode";

export const useUserStore = defineStore({
  id: "user",
  state: () => ({
    id: null,
    loggedIn:
      document.cookie.split("token=")[1]?.split(";")[0] || "" ? true : false,
    tokenWithCookie:
      (document.cookie.split("token=")[1]?.split(";")[0] || "") ?? false,
    token: document.cookie.split("token=")[1]?.split(";")[0] || "",
    errors: [],
    users: [],
    user: [],
    limiteItems: 40,
    sortOrder: "desc",
    sortBy: "created_at",
    countItems: null,
  }),

  getters: {
    getToken: (state) => state.tokenWithCookie,
    getTokenDecoded: (state) =>
      state.tokenWithCookie ? jwtDecode(state.tokenWithCookie) : null,
    getUser: () => {
      const userData = localStorage.getItem("userLocal");
      return userData ? JSON.parse(userData) : {};
    },
    isLoggedIn: (state) => {
      if (!state.token) return false;
      try {
        const decodedToken = jwtDecode(state.token);
        const isExpired = decodedToken.exp * 1000 < Date.now();
        return !isExpired;
      } catch (error) {
        console.error("Failed to decode token:", error);
        return false;
      }
    },
    getErrors: (state) => state.errors,
  },

  actions: {
    async getAllUser(
      page = 1,
      sortOrder = this.sortOrder,
      sortBy = this.sortBy
    ) {
      this.users = [];
      this.loading = true;
      await axios
        .get(
          "/users?sortOrder=" +
            sortOrder +
            "&sortby=" +
            sortBy +
            "&limit=" +
            this.limiteItems +
            "&page=" +
            page
        )
        .then((response) => {
          console.log(response);
          this.loading = false;
          if (response.status == 200) {
            this.users = response.data.item;
            this.countItems = response.data.countItems;
          }
        });
    },

    async getFullUser() {
      this.user = [];
      this.loading = true;
      const userId = localStorage.getItem("userId");
      await axios.get("/users/" + userId).then((response) => {
        console.log(response);
        this.loading = false;
        if (response.status == 200) {
          this.user = response.data;
        }
      });
    },

    async login(props) {
      this.errors = [];
      await axios
        .post("/login_check", props)
        .then((response) => {
          console.log(response);
          if (response.status == 200) {
            const userLocal = response.data.user;
            const token = response.data.token;
            localStorage.setItem("userLocal", JSON.stringify(userLocal));
            localStorage.setItem("userId", userLocal.id);
            // flag Secure, configurer le flag SameSite
            document.cookie = `token=${token}; path=/; SameSite=Strict; Secure`;
            axios.defaults.headers.common["Authorization"] = token;
            this.loggedIn = true;
            this.userLocal = userLocal;
            window.location.href = "/news";
          }
        })
        .catch((error) => {
          console.log(error);
          if (error.response.data.message === "Invalid credentials.") {
            this.errors.push("Identifiant incorrect");
          }
          if (
            error.response.data.message ===
            "Too many failed login attempts, please try again in 1 minute."
          ) {
            this.errors.push(
              "Trop de tentatives de connexion erroné, veuillez réessayer dans 1 minute"
            );
          }
          if (error.response.status === 400) {
            this.errors.push(error.response.data.message);
          }
        });
    },

    logout() {
      this.tokenWithCookie = null;
      this.loggedIn = false;
      document.cookie =
        "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;SameSite=None;Secure";
      localStorage.clear();
      this.$reset();
      window.location.href = "/";
    },

    async deleteUser(id) {
      await axios.delete("/user/" + id).then((response) => {
        console.log(response);
        if (response.status == 204) {
          Swal.fire({
            icon: "success",
            title: "Utilisateur Supprimé !",
            allowOutsideClick: false,
          }).then((result) => {
            if (result.value) {
              window.location.href = "/administration/user";
            }
          });
        }
      });
    },

    async clotureUser() {
      await axios
        .put("/clotureUsers/" + this.getUser.id)
        .then((response) => {
          console.log(response);
          if (response.status == 204) {
            this.tokenWithCookie = null;
            localStorage.clear();
            this.$reset();
            this.$router.push({ name: "login" });
            Swal.fire({
              icon: "success",
              title: "Compte Supprimeé !",
              allowOutsideClick: false,
            }).then((result) => {
              if (result.value) {
                this.$router.push({ name: "home" });
              }
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
});
