<script setup>
import { ref, onMounted } from "vue";
import { useNewsStore } from "@/stores/news";
import { useUserStore } from "@/stores/user";
import { useStatutNewsStore } from "@/stores/lov/statutNews";
import { storeToRefs } from "pinia";
import Swal from "sweetalert2";
import HandlePagination from "@/components/HandlePagination.vue";
import FormSearch from "@/components/FormSearch.vue";
import axios from 'axios';

const newsStore = useNewsStore();
const { news, countItems, limiteItems, newsProduct, startDate, endDate, currentPage, sortBy, sortOrder } = storeToRefs(newsStore);

const statutNewsStore = useStatutNewsStore();
const { statutsNews } = storeToRefs(statutNewsStore);
const { getAllStatutNews } = statutNewsStore;

const { getTokenDecoded } = useUserStore();

// Déclarations réactives
const searchNewsTitle = ref('');
const searchSourceTitle = ref('');
const searchDomainTitle = ref('');
const searchProductTitle = ref('');
const searchImpactLevelUMTitle = ref('');
const searchImpactSuggestionTitle = ref('');
const searchImpactActionUMTitle = ref('');
const searchStatutNewsTitle = ref('');
const searchCustomerName = ref('');

const files = ref([]);

const getSearchFilteredNews = async (
   newsTitle,
   sourceTitle,
   domainTitle,
   productTitle,
   impactLevelUMTitle,
   impactSuggestionTitle,
   impactActionUMTitle,
   statutNewsTitle
) => {
   let params = {
      page: currentPage.value,
      limit: itemsPerPage.value,
      sortBy: "created_at",
      sortOrder: "desc",
      disable: 0,
   };


   if (newsTitle) {
      params.newsTitle = newsTitle;
   }

   if (sourceTitle) {
      params.sourceTitle = sourceTitle;
   }

   if (domainTitle) {
      params.domainTitle = domainTitle;
   }

   if (productTitle) {
      params.productTitle = productTitle;
   }

   if (impactLevelUMTitle) {
      params.impactLevelUMTitle = impactLevelUMTitle;
   }

   if (impactSuggestionTitle) {
      params.impactSuggestionTitle = impactSuggestionTitle;
   }

   if (impactActionUMTitle) {
      params.impactActionUMTitle = impactActionUMTitle;
   }

   if (statutNewsTitle) {
      params.statutNewsTitle = statutNewsTitle;
   }

   try {
      const response = await axios.get("/news", { params });
      if (response.status === 200) {
         news.value = response.data.items;
         countItems.value = response.data.countItems;
      }
   } catch (error) {
      console.error(error);
      // Gérer l'erreur ici
   }
}



onMounted(() => {
   getSearchFilteredNews();
   getAllStatutNews();
   getAllMedia();
   handleWeekChange(selectedWeek.value);
});

/***** Gestion pagination ******/
const itemsPerPage = ref(limiteItems);

const onPageChange = async (page) => {
   currentPage.value = page;
   await getSearchFilteredNews(searchNewsTitle.value, searchSourceTitle.value, searchDomainTitle.value, searchProductTitle.value, searchImpactLevelUMTitle.value, searchImpactSuggestionTitle.value, searchImpactActionUMTitle.value, searchStatutNewsTitle.value, searchCustomerName.value, page);
};
/////////////////////////////////

/***** Methode Recherches ******/
function handleSearch({ type, value }) {
   if (type === 'newsTitle') {
      searchNewsTitle.value = value;
   } else if (type === 'sourceTitle') {
      searchSourceTitle.value = value;
   } else if (type === 'domainTitle') {
      searchDomainTitle.value = value;
   } else if (type === 'productTitle') {
      searchProductTitle.value = value;
   } else if (type === 'impactLevelUMTitle') {
      searchImpactLevelUMTitle.value = value;
   } else if (type === 'impactSuggestionTitle') {
      searchImpactSuggestionTitle.value = value;
   } else if (type === 'impactActionUMTitle') {
      searchImpactActionUMTitle.value = value;
   } else if (type === 'statutNewsTitle') {
      searchStatutNewsTitle.value = value;
   }
   currentPage.value = 1; // remmettre à la 1er page
   getSearchFilteredNews(searchNewsTitle.value, searchSourceTitle.value, searchDomainTitle.value, searchProductTitle.value, searchImpactLevelUMTitle.value, searchImpactSuggestionTitle.value, searchImpactActionUMTitle.value, searchStatutNewsTitle.value, searchCustomerName.value);
}
///////////////////////////////

/**** Reset les filtre et affiche toutes les news actives ****/
const getAllNewsActive = () => {

   currentPage.value = 1; // remmettre à la 1er page
   limiteItems.value = 25; // Remettre la valeur initiale  de limite 

   // Reset les newsProduct
   newsProduct.value = null;

   // reset les dates   
   startDate.value = "";
   endDate.value = "";
   showWarning.value = false;

   selectedWeek.value = null;

   // reset les termes de recherche
   searchNewsTitle.value = '';
   searchSourceTitle.value = '';
   searchDomainTitle.value = '';
   searchProductTitle.value = '';
   searchImpactLevelUMTitle.value = '';
   searchImpactSuggestionTitle.value = '';
   searchImpactActionUMTitle.value = '';
   searchStatutNewsTitle.value = '';
   searchCustomerName.value = '',

      getSearchFilteredNews();
}
/////////////////////////////

/***** Choisir le nombre de news à afficher *****/
const nbItemArray = [5, 25, 50];
const getNbNews = async (limiteItems, page) => {
   currentPage.value = 1;
   await getSearchFilteredNews(searchNewsTitle.value, searchSourceTitle.value, searchDomainTitle.value, searchProductTitle.value, searchImpactLevelUMTitle.value, searchImpactSuggestionTitle.value, searchImpactActionUMTitle.value, searchStatutNewsTitle.value, searchCustomerName.value, limiteItems, page);
}
/////////////////////////////


/****** Recherche et Mise en evidence de la Date ******/
const hoverForDate = ref(false);
const showWarning = ref(false);

const searchByDate = () => {
   if (!startDate.value, !endDate.value) {
      showWarning.value = true;
      return;
   } else {
      showWarning.value = false;
      getSearchFilteredNews(searchNewsTitle.value, searchSourceTitle.value, searchDomainTitle.value, searchProductTitle.value, searchImpactLevelUMTitle.value, searchImpactSuggestionTitle.value, searchImpactActionUMTitle.value, searchStatutNewsTitle.value, searchCustomerName.value, startDate.value, endDate.value);
   }
};

// Recherche par semaine //
const selectedWeek = ref(getCurrentWeekNumber());

function getCurrentWeekNumber() {
   const currentDate = new Date();
   const startOfYear = new Date(currentDate.getFullYear(), 0, 1);
   const pastDaysOfYear = (currentDate - startOfYear) / 86400000;
   return Math.ceil((pastDaysOfYear + startOfYear.getDay() + 1) / 7);
}

function handleWeekChange(weekNumber) {
   const currentYear = new Date().getFullYear();
   const startOfYear = new Date(currentYear, 0, 1);
   const daysOffset = (weekNumber - 1) * 7;
   const monday = new Date(startOfYear.setDate(startOfYear.getDate() - startOfYear.getDay() + daysOffset + 1));
   const sunday = new Date(monday);
   sunday.setDate(monday.getDate() + 6);

   startDate.value = monday.toISOString().split("T")[0];
   endDate.value = sunday.toISOString().split("T")[0];

   getSearchFilteredNews(
      searchNewsTitle.value,
      searchSourceTitle.value,
      searchDomainTitle.value,
      searchProductTitle.value,
      searchImpactLevelUMTitle.value,
      searchImpactSuggestionTitle.value,
      searchImpactActionUMTitle.value,
      searchStatutNewsTitle.value
   );
}
//////////////////////////////////////////





/***** Filtrer oui/non ou N/A par actualité/Produit ******/
const selectedOption = ref('');

const handleFilter = async () => {
   let newsProductValue;
   switch (selectedOption.value) {
      case 'oui':
         newsProductValue = 'oui';
         break;
      case 'non':
         newsProductValue = 'non';
         break;
      case 'na':
         newsProductValue = 'na';
         break;
      default:
         newsProductValue = '';
   }

   await getSearchFilteredNews(
      searchNewsTitle.value,
      searchSourceTitle.value,
      searchDomainTitle.value,
      searchProductTitle.value,
      searchCustomerName.value,
      searchImpactLevelUMTitle.value,
      searchImpactSuggestionTitle.value,
      searchImpactActionUMTitle.value,
      searchStatutNewsTitle.value,
      newsProduct.value = newsProductValue
   );
};
/////////////////////////////////////

/***** Afficher les news en cours ou clos ******/
const filterByAction = () => {
   currentPage.value = 1; // Réinitialiser la pagination à la première page
   getSearchFilteredNews(searchNewsTitle.value, searchSourceTitle.value, searchDomainTitle.value, searchCustomerName.value, searchProductTitle.value, searchImpactLevelUMTitle.value, searchImpactSuggestionTitle.value, searchImpactActionUMTitle.value, searchStatutNewsTitle.value);
};
//////////////////////////////////////

/******* Le trie par ordre et type ******/
const isDeadlineSorted = ref(false);
const handleSortNews = (field) => {
   if (sortBy.value === field) {
      sortOrder.value = sortOrder.value === 'ASC' ? 'DESC' : 'ASC'; // Toggle sort order
      isDeadlineSorted.value = !isDeadlineSorted.value;
   } else {
      sortBy.value = field;
      sortOrder.value = 'ASC'; // Default to ascending order
      isDeadlineSorted.value = false;
   }
   currentPage.value = 1; // Réinitialiser à la première page
   getSearchFilteredNews(searchNewsTitle.value, searchSourceTitle.value, searchDomainTitle.value, searchCustomerName.value, searchProductTitle.value, searchImpactLevelUMTitle.value, searchImpactSuggestionTitle.value, searchImpactActionUMTitle.value, searchStatutNewsTitle.value);
};
//////////////////////////////////////

/**** Modal pour afficher l'actualité *****/
const isModalOpen = ref(false);
const modalContent = ref('');

const openNewsModal = (news) => {
   modalContent.value = news;
   isModalOpen.value = true;
};

const closeModal = () => {
   isModalOpen.value = false;
};
//////////////////////////////////////////////


/**** GET UPLOAD FILE *****/
const downloadFile = (filename) => {
   axios.get(`/file/${filename}`, {
      responseType: 'blob'
   })
      .then(response => {
         const url = window.URL.createObjectURL(new Blob([response.data]));
         const link = document.createElement('a');
         link.href = url;
         link.setAttribute('download', filename);
         document.body.appendChild(link);
         link.click();
      })
      .catch(error => {
         console.error('Erreur lors du téléchargement du fichier:', error);
      });
};


/******** DESACTIVER ********/
const disableNews = (news) => {
   Swal.fire({
      title: "Attention",
      text: "Confirmez-vous la desactivation de cette actualité ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      confirmButtonText: "Confirmer",
      cancelButtonText: "Annuler",
      allowOutsideClick: false,
   }).then((result) => {
      if (result.isConfirmed) {
         newsStore.disableNews(news);
      }
   });
};

/******** SUPPRIMER ********/
const deleteNews = (id) => {
   Swal.fire({
      title: "Attention",
      text: "Confirmez-vous la suppression de cette actualité ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      confirmButtonText: "Confirmer",
      cancelButtonText: "Annuler",
      allowOutsideClick: false,
   }).then((result) => {
      if (result.isConfirmed) {
         newsStore.deleteNews(id);
      }
   });
};


const medias = ref([]);
const loading = ref(true);

const getAllMedia = async () => {
   try {
      const response = await axios.get("/medias");
      if (response.status === 200) {
         console.log("mediaaaa :", response)
         medias.value = response.data;
      }
   } catch (error) {
      console.error('There was an error!', error);
   } finally {
      loading.value = false;
   }
};


</script>

<template>
   <div class="content">

      <nav class="breadcrumbs">
         <div class="breadcrumbs-content">
            <span><small>Actualités...</small></span>
         </div>
      </nav>

      <h1>LISTE DES ACTUALITES <small> ({{ countItems }}) </small></h1>

      <div class="btn_actualite">
         <div class="btn">
            <router-link to="/news/add">
               <button>
                  <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-file-plus" width="24"
                     height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                     stroke-linecap="round" stroke-linejoin="round">
                     <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                     <path d="M14 3v4a1 1 0 0 0 1 1h4" />
                     <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" />
                     <path d="M12 11l0 6" />
                     <path d="M9 14l6 0" />
                  </svg>
                  <div>Ajouter une actualité</div>
               </button>
            </router-link>
         </div>

         <div class="btn" @click="getAllNewsActive">
            <div v-for="media in medias" :key="media.id" class="media-item">
            </div>
            <button>
               <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-restore" width="24"
                  height="24" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none"
                  stroke-linecap="round" stroke-linejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M3.06 13a9 9 0 1 0 .49 -4.087" />
                  <path d="M3 4.001v5h5" />
                  <path d="M12 12m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0" />
               </svg>
               <div>Réinitialise les filtres</div>
            </button>
         </div>

      </div>

      <div class="date">
         <select val class="button_semaine" v-model="selectedWeek" @change="handleWeekChange(selectedWeek)">
            <option value="" disabled selected>Semaine...</option>
            <option v-for="week in 52" :key="week" :value="week">Semaine {{ week }}</option>
         </select>
         <input @focus="hoverForDate = true" @blur="hoverForDate = false" type="date" v-model="startDate" />
         <p>au</p>
         <input @focus="hoverForDate = true" @blur="hoverForDate = false" type="date" v-model="endDate" />
         <div class="button" :title="searchByDate.value ? '' : 'Veuillez choisir une date'"
            @click.prevent="searchByDate">
            <div class="btn_pulse">Rechercher <small>(date de détection)</small></div>
         </div>
      </div>

      <div class="warning">
         <span v-if="showWarning"><small>Veuillez choisir une date</small></span>
      </div>

      <div class="content_nbLine">
         <div class="nb_line">
            <p>Nombre d'actualité</p>
            <input type="range" min="5" max="50" v-model="limiteItems" @change="getNbNews(limiteItems)" class="slider">
            <ul class="slider-labels">
               <li v-for="(item, index) in nbItemArray" :key="index">{{ item }}</li>
            </ul>
         </div>
      </div>


      <div class="date_news" v-if="startDate && endDate">
         <p>
            Actualité de la semaine : <strong>{{ startDate }}</strong> au <strong>{{ endDate }}</strong> <br>
            A trier
         </p>
      </div>

      <div class="table_body">
         <table>
            <thead>
               <tr class="title-row">
                  <th>n°</th>
                  <th>Date de<br />détection</th>
                  <th class="title_actualite">Titre (avec URL)</th>
                  <th>Source</th>
                  <th>Actualité<br />Produit</th>
                  <th>Domaine</th>
                  <th>Produit concerné</th>
                  <th>Impact et Suggestions d'actions à réaliser par le client</th>
                  <th class="um">Niveau impact projets UM</th>
                  <th class="um">Impact et Actions à mettre en place</th>
                  <th class="um">Statut</th>
                  <th>Pièce jointe</th>
                  <th>V/M/D</th>
               </tr>
            </thead>
            <thead class="recherche">
               <tr class="first-head-row">
                  <td></td>
                  <td class="sort_deadline">
                     <div @click="handleSortNews('id')" :class="{ active: isDeadlineSorted }">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                           stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                           class="icon icon-tabler icons-tabler-outline icon-tabler-arrows-up-down">
                           <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                           <path d="M7 3l0 18" />
                           <path d="M10 6l-3 -3l-3 3" />
                           <path d="M20 18l-3 3l-3 -3" />
                           <path d="M17 21l0 -18" />
                        </svg>
                     </div>
                  </td>
                  <td>
                     <FormSearch @search="handleSearch" searchType="newsTitle" placeholder="Recherche par titre..." />
                     <div v-if="searchNewsTitle">
                        <small>Recherche pour : </small> {{ searchNewsTitle }}
                     </div>
                  </td>
                  <td>
                     <FormSearch @search="handleSearch" searchType="sourceTitle"
                        placeholder="Recherche par source..." />
                     <div v-if="searchSourceTitle">
                        <small>Recherche pour : </small> {{ searchSourceTitle }}
                     </div>
                  </td>
                  <td>
                     <div class="newsProduct">
                        <select v-model="selectedOption" @change="handleFilter">
                           <option disabled value="">Choisir une veleur</option>
                           <option value="oui">Oui</option>
                           <option value="non">Non</option>
                           <option value="na">N/A</option>
                        </select>
                     </div>
                  </td>
                  <td>
                     <FormSearch @search="handleSearch" searchType="domainTitle"
                        placeholder="Recherche par domaine..." />
                     <div v-if="searchDomainTitle">
                        <small>Recherche pour : </small> {{ searchDomainTitle }}
                     </div>
                  </td>
                  <td>
                     <FormSearch @search="handleSearch" searchType="productTitle"
                        placeholder="Recherche par Produit..." />
                     <div v-if="searchProductTitle">
                        <small>Recherche pour : </small> {{ searchProductTitle }}
                     </div>
                  </td>
                  <td>
                     <FormSearch @search="handleSearch" searchType="impactSuggestionTitle"
                        placeholder="Recherche par suggestion..." />
                     <div v-if="searchImpactSuggestionTitle">
                        <small>Recherche pour : </small> {{ searchImpactSuggestionTitle }}
                     </div>
                  </td>
                  <td>
                     <FormSearch @search="handleSearch" searchType="impactLevelUMTitle"
                        placeholder="Recherche par niveau d'impact UM..." />
                     <div v-if="searchImpactLevelUMTitle">
                        <small>Recherche pour : </small> {{ searchImpactLevelUMTitle }}
                     </div>
                  </td>
                  <td>
                     <FormSearch @search="handleSearch" searchType="impactActionUMTitle"
                        placeholder="Recherche par suggestion UM..." />
                     <div v-if="searchImpactActionUMTitle">
                        <small>Recherche pour : </small> {{ searchImpactActionUMTitle }}
                     </div>
                  </td>
                  <td>
                     <select v-model="searchStatutNewsTitle" @change="filterByAction">
                        <option disabled value="">Statut des news</option>
                        <option v-for="statutNews in statutsNews" :key="statutNews.id" :value="statutNews.title">{{
                           statutNews.title }}
                        </option>
                     </select>
                  </td>
                  <td></td>
                  <td></td>
               </tr>
            </thead>
            <tbody>
               <tr v-for="(item, index) in news" :key="item.id">

                  <td>{{ item.id }}</td>
                  <td :class="{ 'detection': hoverForDate }">
                     <p>{{ item.detection_at }}</p>
                  </td>
                  <td>
                     <a :href="item.url" target="_blank">{{ item.title }}</a>
                  </td>
                  <td>
                     <div v-for="source in item.source" :key="source.id">
                        {{ source.title }}
                     </div>
                  </td>
                  <td v-if="item.newsProduct == 'oui'">Oui</td>
                  <td v-else-if="item.newsProduct == 'non'">Non</td>
                  <td v-else>N/A</td>
                  <td>
                     <div v-for="domain in item.domain" :key="domain.id">
                        {{ domain.title }}
                     </div>
                  </td>
                  <td>
                     <div v-for="product in item.product" :key="product.id">
                        {{ product.title }}
                     </div>
                  </td>
                  <td>
                     <div v-for="impactSuggestion in item.impactSuggestion" :key="impactSuggestion.id">
                        {{ impactSuggestion.title }}
                     </div>
                  </td>
                  <td>
                     <div v-for="impactLevelUM in item.impactLevelUM" :key="impactLevelUM.id">
                        {{ impactLevelUM.title }}
                     </div>
                  </td>
                  <td>
                     <div v-for="impactActionUM in item.impactActionUM" :key="impactActionUM.id">
                        {{ impactActionUM.title }}
                     </div>
                  </td>
                  <td>
                     <div v-for="statutNews in item.statutNews" :key="statutNews.id">
                        {{ statutNews.title }}
                     </div>
                  </td>
                  <td class="btn_upload" v-if="item.medias && item.medias[0]">

                     <button class="upload" @click="downloadFile(item.medias[0].title)"><svg
                           xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                           stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                           class="icon icon-tabler icons-tabler-outline icon-tabler-cloud-upload">
                           <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                           <path d="M7 18a4.6 4.4 0 0 1 0 -9a5 4.5 0 0 1 11 2h1a3.5 3.5 0 0 1 0 7h-1" />
                           <path d="M9 15l3 -3l3 3" />
                           <path d="M12 12l0 9" />
                        </svg>
                        {{ item.medias[0].title }} </button>
                  </td>
                  <td v-else></td>
                  <td>
                     <div class="btn_action">
                        <div @click="openNewsModal(item)" title="Voir" class="show_news">
                           <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-eye" width="20"
                              height="20" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                              stroke-linecap="round" stroke-linejoin="round">
                              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                              <path d="M10 12a2 2 0 1 0 4 0a2 2 0 0 0 -4 0" />
                              <path
                                 d="M21 12c-2.4 4 -5.4 6 -9 6c-3.6 0 -6.6 -2 -9 -6c2.4 -4 5.4 -6 9 -6c3.6 0 6.6 2 9 6" />
                           </svg>
                        </div>
                        <RouterLink :to="{ name: 'editNews', params: { slug: item.slug } }" title="Modifier"
                           class="edit">
                           <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-edit" width="20"
                              height="20" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                              stroke-linecap="round" stroke-linejoin="round">
                              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                              <path d="M7 7h-1a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-1" />
                              <path d="M20.385 6.585a2.1 2.1 0 0 0 -2.97 -2.97l-8.415 8.385v3h3l8.385 -8.415z" />
                              <path d="M16 5l3 3" />
                           </svg>
                        </RouterLink>
                        <a @click.prevent="disableNews(item.id)" title="désactiver" class="delete">
                           <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                              fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                              stroke-linejoin="round" class="icon icon-tabler icons-tabler-outline icon-tabler-x">
                              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                              <path d="M18 6l-12 12" />
                              <path d="M6 6l12 12" />
                           </svg>
                        </a>
                        <a v-if="getTokenDecoded && getTokenDecoded.roles.includes('ROLE_SUPER_ADMIN')"
                           @click.prevent="deleteNews(item.id)" title="supprimer" class="delete">
                           <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-trash" width="20"
                              height="20" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                              stroke-linecap="round" stroke-linejoin="round">
                              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                              <path d="M4 7l16 0" />
                              <path d="M10 11l0 6" />
                              <path d="M14 11l0 6" />
                              <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />
                              <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
                           </svg>
                        </a>
                     </div>
                  </td>
               </tr>

            </tbody>
         </table>
      </div>


      <!----------------------- MODAL--------------------------------->
      <div class="modal" :class="{ 'is-open': isModalOpen }">
         <div class="modal-content">
            <table>
               <tr>
                  <th scope="col">Création de l'actualité</th>
                  <th v-if="modalContent.createdBy" scope="col">{{ modalContent.created_at }} par
                     {{ modalContent.createdBy.lastname }} {{ modalContent.createdBy.firstname }}</th>
               </tr>
               <tr>
                  <th scope="row">Id</th>
                  <td>{{ modalContent.id }}</td>
               </tr>
               <tr>
                  <th scope="row">Titre</th>
                  <td>{{ modalContent.title }}</td>
               </tr>
               <tr>
                  <th scope="row">Date de détection</th>
                  <td>{{ modalContent.detection_at }}</td>
               </tr>
               <tr>
                  <th scope="row">Date de publication de l'auteur</th>
                  <td>{{ modalContent.published_at }}</td>
               </tr>
               <tr v-for="source in modalContent.source" :key="source.id">
                  <th scope="row">Source</th>
                  <td>{{ source.title }}</td>
               </tr>
               <tr v-for="impactSuggestion in modalContent.impactSuggestion" :key="impactSuggestion.id">
                  <th scope="row">Impact & Suggestion</th>
                  <td>{{ impactSuggestion.title }}</td>
               </tr>
               <tr v-for="statutNews in modalContent.statutNews" :key="statutNews.id">
                  <th scope="row">Statut</th>
                  <td>{{ statutNews.title }}</td>
               </tr>
               <tr v-for="domain in modalContent.domain" :key="domain.id">
                  <th scope="row">Domaine</th>
                  <td>{{ domain.title }}</td>
               </tr>
               <tr v-for="product in modalContent.product" :key="product.id">
                  <th scope="row">Produit</th>
                  <td>{{ product.title }}</td>
               </tr>
               <tr v-for="action in modalContent.action" :key="action.id">
                  <th scope="row">Action</th>
                  <td>{{ action.title }}</td>
               </tr>
               <tr v-for="impactLevelUM in modalContent.impactLevelUM" :key="impactLevelUM.id">
                  <th scope="row">Niveau impact projets UM </th>
                  <td>{{ impactLevelUM.title }}</td>
               </tr>
               <tr v-for="impactActionUM in modalContent.impactActionUM" :key="impactActionUM.id">
                  <th scope="row">Impact et Actions à mettre en place</th>
                  <td>{{ impactActionUM.title }}</td>
               </tr>
               <tr>
                  <th scope="row">Réseaux sociaux</th>
                  <td>{{ modalContent.socialNetwork }}</td>
               </tr>
               <tr>
                  <th scope="row">Déscription</th>
                  <td>{{ modalContent.description }}</td>
               </tr>
            </table>
            <br>
            <div class="button_close">
               <button @click="closeModal">Fermer</button>
            </div>
         </div>
      </div>
      <!----------------------- FIN MODAL--------------------------------->

      <div class="pagination">
         <HandlePagination :totalPages="Math.ceil(countItems / itemsPerPage)" :itemsPerPage="Number(itemsPerPage)"
            :currentPage="currentPage" @pagechanged="onPageChange" />
      </div>
   </div>
</template>

<style scoped lang="scss">
.content {
   margin-top: 70px;
   min-height: 100vh;
}

h1 {
   text-align: center;
   padding: 10px 0;

   small {
      position: relative;
      bottom: 18px;
      font-size: 18px;
   }
}

.btn_description {
   @include button;
}

/********* MODAL ***********/
.modal {
   display: none;
   position: fixed;
   z-index: 1;
   left: 0;
   top: 0;
   width: 100%;
   height: 100%;
   overflow: auto;
   background-color: rgba(0, 0, 0, 0.4);
   transition: opacity 0.3s ease;
   backdrop-filter: blur(7px); // Ajout du flou

   .button_close {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 1.5rem;

      button {
         @include button;
      }

   }
}

.modal.is-open {
   display: block;
   visibility: visible;
   opacity: 1;
   transition-delay: 0s;
}

.modal-content {
   background-color: #eee9e9;
   margin: 15% auto;
   padding: 3em 4em;
   width: 70%;
   transform: scale(0.7);
   transition: transform 0.3s ease;
   text-align: -moz-center;
   box-shadow: 2px 2px 10px 2px rgba(31, 31, 31, 0.521);


   @media screen and (max-width: 890px) {
      width: 90%;
      margin: 250px auto;
   }

   @media screen and (max-width: 515px) {
      width: 90%;
      margin: 250px auto;
      padding: 10px;
   }

   p {
      color: rgb(0, 0, 0);
   }
}

.modal.is-open .modal-content {
   transform: scale(1);
}

th,
td {
   border: 1px solid rgb(160 160 160);
   padding: 8px 10px;
}

th[scope='col'] {
   background-color: #505050;
   color: #fff;
}

th[scope='row'] {
   background-color: #d4e3ec;
}

td {
   text-align: center;
}

tr:nth-of-type(even) {
   background-color: #eee;
}

table {
   border-collapse: collapse;
   border: 2px solid rgb(140 140 140);
   font-family: sans-serif;
   font-size: 0.8rem;
   letter-spacing: 1px;
}

//////////////////////////////


/******** FILTRE  ********/
.content_nbLine {
   @include slider_btn;
   display: flex;
   justify-content: center;
   margin: 30px 0;

   p {
      text-align: center;
      margin-bottom: 10px;
   }
}


.date {
   display: flex;
   justify-content: center;
   align-items: center;
   margin: 3rem 0 2.25rem 0;
   flex-wrap: wrap;
   gap: 20px;

   .button_semaine {
      @include button;
   }

   input {
      padding: 15px 20px;
      font-size: $font-size-4;
      cursor: pointer;
      margin: 0 20px;
      box-shadow: 1px 1px 5px 5px rgba(73, 73, 73, 0.151);
      border-radius: 10px;
      border: none;
      transition: all 0.2s ease-in-out;

      &:hover {
         transition: all 0.2s ease-in-out;
         cursor: pointer;
         box-shadow: 1px 1px 5px 5px rgba(100, 100, 100, 0.315);
      }

      &:focus {
         transition: all 0.2s ease-in-out;
         transform: scale(0.98);
         box-shadow: 1px 1px 5px 5px rgba(0, 0, 0, 0.089);
      }
   }

   .button {
      @include btn_pulse;
   }

   @media (max-width: 472px) {
      display: grid;
      text-align: center;
   }
}

.warning {
   position: relative;
   left: 13em;
   bottom: 30px;
   text-align: center;

   span {
      color: rgb(214, 59, 59);
   }
}

.date_news {
   display: flex;
   justify-content: center;
   align-items: center;
   margin: 2rem 0;
   gap: 20px;

   p {
      font-size: 1.5rem;
      text-align: center;
   }
}

.detection {
   background: $blue_3;
   transition: all 0.3s ease-in-out;

   p {
      transition: all 0.3s ease-in-out;
      color: white;
   }
}

.warning {
   position: relative;
   left: 13em;
   bottom: 30px;
   text-align: center;

   span {
      color: rgb(214, 59, 59);
   }
}


.newsProduct {
   display: flex;
   justify-content: space-around;

   &__true {
      margin-right: 15px;
   }

   label {
      margin-left: 5px;
   }

   input,
   label {
      cursor: pointer;

      &:hover {
         box-shadow: 1px 1px 10px 1px rgba(39, 39, 211, 0.192);
      }
   }
}

/////////////////////////

/******** BOUTTON ********/
.btn_action {
   display: flex;
   justify-content: space-between;
   padding: 7px;
   align-items: center;

   a {
      cursor: pointer;
   }

   .edit {
      color: rgba(0, 0, 255, 0.658);

      &:hover {
         color: rgb(56, 56, 245);
         transform: scale(1.2);
      }
   }

   .delete {
      color: rgba(255, 0, 0, 0.616);

      &:hover {
         color: red;
         transform: scale(1.2);
      }
   }

   .show_news {
      cursor: pointer;
      padding: 5px;

      &:hover {
         transform: scale(1.2);
      }
   }
}

.btn_actualite {
   display: flex;
   justify-content: center;
   align-items: center;
   gap: 1.5rem;
   margin: 2.5rem 2rem;
   flex-wrap: wrap;

   .btn {
      button {
         display: flex;
         justify-content: center;
         display: flex;
         min-width: 330px;
         align-items: center;
         @include button;
         gap: 1rem;
      }
   }
}

.btn_upload {

   button {
      @include button;
      width: 110px;
      font-size: 14px;
      padding: 7px 3px;
   }
}

/////////////////////////////////////

/******** TABLE ********/
.table_body {
   @include table;
   margin: auto;
}

@media (max-width: 890px) {

   /* Masquer toutes les colonnes sauf la première, la deuxième, la troisième, la quatri et la dernière */
   .table_body th:not(:nth-child(1)):not(:nth-child(2)):not(:nth-child(3)):not(:last-child),
   .table_body td:not(:nth-child(1)):not(:nth-child(2)):not(:nth-child(3)):not(:last-child) {
      display: none;
   }
}

@media (max-width: 530px) {
   h1 {
      font-size: 1.8em;
      margin: 0 10px;
   }

   /* Masquer toutes les colonnes sauf la première, la deuxième, et la dernière */
   .table_body th:not(:nth-child(1)):not(:nth-child(2)):not(:last-child),
   .table_body td:not(:nth-child(1)):not(:nth-child(2)):not(:last-child) {
      display: none;
   }
}
</style>
