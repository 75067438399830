import { defineStore } from "pinia";
import axios from "axios";
import Swal from "sweetalert2";

export const useNewsStore = defineStore("news", {
  state: () => {
    const currentDate = new Date();
    const startDate = new Date();
    const dayOfWeek = currentDate.getDay(); // 0 (Dimanche) à 6 (Samedi)

    // Ajuster startDate au lundi de la semaine actuelle
    startDate.setDate(
      currentDate.getDate() - (dayOfWeek === 0 ? 6 : dayOfWeek - 1)
    );

    // Ajuster endDate au dimanche de la semaine actuelle
    const endDate = new Date(startDate);
    endDate.setDate(startDate.getDate() + 6);

    return {
      id: null,
      errors: [],
      news: [],
      newsByExport: [],
      countItemsByExport: null,
      newsDTO: [],
      gestionAction: [],
      gestionAction_id: null,
      limiteItems: 30,
      currentPage: 1,
      page: null,
      sortOrder: "desc",
      sortBy: "created_at",
      countItems: null,
      disable: 0,
      newsProduct: null,
      startDate: startDate.toISOString().split("T")[0], // Définir la date de début à 7 jours avant la date actuelle
      endDate: currentDate.toISOString().split("T")[0], // Définir la date de fin à la date actuelle
    };
  },

  getters: {
    getErrors: (state) => state.errors,
  },

  actions: {
    
    async getSearchFilteredNews(
      newsTitle,
      sourceTitle,
      domainTitle,
      productTitle,
      impactLevelUMTitle,
      impactSuggestionTitle,
      impactActionUMTitle,
      statutNewsTitle
    ) {
      this.news = [];
      let params = {
        page: this.currentPage,
        limit: this.limiteItems,
        sortBy: this.sortBy,
        sortOrder: this.sortOrder,
        disable: this.disable,
      };

      if (this.newsProduct !== null) {
        params.newsProduct = this.newsProduct;
      }

      if (newsTitle) {
        params.newsTitle = newsTitle;
      }

      if (sourceTitle) {
        params.sourceTitle = sourceTitle;
      }

      if (domainTitle) {
        params.domainTitle = domainTitle;
      }

      if (productTitle) {
        params.productTitle = productTitle;
      }

      if (impactLevelUMTitle) {
        params.impactLevelUMTitle = impactLevelUMTitle;
      }

      if (impactSuggestionTitle) {
        params.impactSuggestionTitle = impactSuggestionTitle;
      }

      if (impactActionUMTitle) {
        params.impactActionUMTitle = impactActionUMTitle;
      }

      if (statutNewsTitle) {
        params.statutNewsTitle = statutNewsTitle;
      }

      if (this.startDate && this.endDate) {
        params.startDate = this.startDate;
        params.endDate = this.endDate;
      }

      try {
        const response = await axios.get("/news", { params });
        if (response.status === 200) {
          this.news = response.data.items;
          this.countItems = response.data.countItems;
        }
      } catch (error) {
        console.error(error);
        // Gérer l'erreur ici
      }
    },

    /**** News pour l'export *****/
    async getSearchFilteredNewsByExport(
      newsTitle,
      sourceTitle,
      domainTitle,
      productTitle,
      impactLevelUMTitle,
      impactSuggestionTitle,
      impactActionUMTitle,
      statutNewsTitle,
      customerName
    ) {
      this.newsByExport = [];
      let params = {
        page: this.currentPage,
        limit: this.limiteItems,
        sortBy: this.sortBy,
        sortOrder: this.sortOrder,
        disable: this.disable,
      };

      if (this.newsProduct !== null) {
        params.newsProduct = this.newsProduct;
      }

      if (newsTitle) {
        params.newsTitle = newsTitle;
      }

      if (sourceTitle) {
        params.sourceTitle = sourceTitle;
      }

      if (domainTitle) {
        params.domainTitle = domainTitle;
      }

      if (productTitle) {
        params.productTitle = productTitle;
      }

      if (impactLevelUMTitle) {
        params.impactLevelUMTitle = impactLevelUMTitle;
      }

      if (impactSuggestionTitle) {
        params.impactSuggestionTitle = impactSuggestionTitle;
      }

      if (impactActionUMTitle) {
        params.impactActionUMTitle = impactActionUMTitle;
      }

      if (customerName) {
        params.customerName = customerName;
      }

      if (statutNewsTitle) {
        params.statutNewsTitle = statutNewsTitle;
      }

      if (this.startDate && this.endDate) {
        params.startDate = this.startDate;
        params.endDate = this.endDate;
      }

      try {
        const response = await axios.get("/newsByExport", { params });
        if (response.status === 200) {
          this.newsByExport = response.data.items;
          this.countItemsByExport = response.data.countItems;
        }
      } catch (error) {
        console.error(error);
        // Gérer l'erreur ici
      }
    },

    async getAllNewsEnable(
      page = 1,
      sortOrder = this.sortOrder,
      sortBy = this.sortBy
    ) {
      this.news = [];
      this.loading = true;
      await axios
        .get(
          "/news-enable?sortOrder=" +
            sortOrder +
            "&sortBy=" +
            sortBy +
            "&limit=" +
            this.limiteItems +
            "&page=" +
            page
        )
        .then((response) => {
          console.log(response);
          this.loading = false;
          if (response.status == 200) {
            this.news = response.data.item;
            this.countItems = response.data.countItems;
          }
        });
    },

    async getAllNewsDisable(
      page = 1,
      sortOrder = this.sortOrder,
      sortBy = this.sortBy
    ) {
      this.news = [];
      this.loading = true;
      await axios
        .get(
          "/news-disable?limit =" +
            this.limiteItems +
            "&sortBy=" +
            sortBy +
            "&sortOrder=" +
            sortOrder +
            "&page=" +
            page
        )
        .then((response) => {
          console.log(response);
          this.loading = false;
          if (response.status == 200) {
            this.news = response.data.item;
            this.countItems = response.data.countItems;
          }
        });
    },

    async getNews(slug) {
      this.news = [];
      this.loading = true;
      await axios
        .get("/news/" + slug)
        .then((response) => {
          console.log(response);
          this.loading = false;
          if (response.status == 200) {
            this.news = response.data;
          }
        })
        .catch((error) => {
          console.log(error);
          if (error.response.status == 404) {
            //this.$router.push({ name: "not-found" });
          }
        });
    },

    async deleteNews(id) {
      await axios.delete("/news/" + id).then((response) => {
        console.log(response);
        if (response.status == 204) {
          Swal.fire({
            icon: "success",
            title: "Actualité Supprimé !",
            allowOutsideClick: false,
          }).then(async (result) => {
            if (result.value) {
              await this.getSearchFilteredNews();
            }
          });
        }
      });
    },

    async disableNews(id) {
      await axios.put("/disableNews/" + id).then((response) => {
        console.log(response);
        if (response.status == 204) {
          Swal.fire({
            icon: "success",
            title: "Actualité Désactivé !",
            allowOutsideClick: false,
          }).then(async (result) => {
            if (result.value) {
              await this.getSearchFilteredNews();
            }
          });
        }
      });
    },

    async enableNews(id) {
      await axios.put("/enableNews/" + id).then((response) => {
        console.log(response);
        if (response.status == 204) {
          Swal.fire({
            icon: "success",
            title: "Actualité Activé !",
            text: "Vous allez être redirigé vers la liste des actualités actifs.",
            allowOutsideClick: false,
          }).then(async (result) => {
            if (result.value) {
              window.location.href = "/news";
            }
          });
        }
      });
    },

    async exportNewsPdf(ids) {
      try {
        const response = await axios.post('/export/news/pdf', { ids }, {
          responseType: 'blob' // Important pour gérer le fichier PDF
        });
        
        // Créer un lien temporaire pour télécharger le fichier
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        const filename = 'news_export_' + new Date().toISOString().slice(0, 10) + '.pdf';
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        
        // Nettoyage
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      } catch (error) {
        console.error("Erreur lors de l'exportation du PDF:", error);
        throw error;
      }
    },


    async getNewsForAction(
      newsTitle,
      action_realiser,
      createdBy,
      updatedBy,
      statusAction
    ) {
      this.gestionAction = [];
      let params = {
        page: this.currentPage,
        limit: this.limiteItems,
        sortBy: this.sortBy,
        sortOrder: this.sortOrder,
      };

      if (this.newsProduct !== null) {
        params.newsProduct = this.newsProduct;
      }

      if (newsTitle) {
        params.newsTitle = newsTitle;
      }

      if (action_realiser) {
        params.action_realiser = action_realiser;
      }

      if (createdBy) {
        params.createdBy = createdBy;
      }

      if (updatedBy) {
        params.updatedBy = updatedBy;
      }

      if (statusAction) {
        params.statusAction = statusAction;
      }

      try {
        const response = await axios.get("/gestionActions", { params });
        if (response.status === 200) {
          this.gestionAction = response.data.item;
          this.countItems = response.data.countItems;
        }
      } catch (error) {
        console.error(error);
        // Gérer l'erreur ici
      }
    },

    async getOneNewsForAction(gestionAction_id) {
      this.gestionAction = [];

      await axios
        .get("/gestionActions/" + gestionAction_id)
        .then((response) => {
          console.log(response);
          this.loading = false;
          if (response.status == 200) {
            this.gestionAction = response.data;
          }
        })
        .catch((error) => {
          console.log(error);
          if (error.response.status == 404) {
            //this.$router.push({ name: "not-found" });
          }
        });
    },

    async getNewsDTO() {
      this.newsDTO = [];

      try {
        const response = await axios.get("/newsDTO");
        if (response.status === 200) {
          console.log(response.data);
          this.newsDTO = response.data;
          // this.countItems = response.data.countItems;
        }
      } catch (error) {
        console.error(error);
        // Gérer l'erreur ici
      }
    },
  },
});
