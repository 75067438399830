<script setup>
import { onMounted, reactive, ref, computed } from 'vue';
import Swal from "sweetalert2";
import { useRoute, onBeforeRouteLeave } from 'vue-router';
import { storeToRefs } from 'pinia';
import { useUserStore } from '@/stores/user';
import { useNewsStore } from '@/stores/news';
import { useStatutActionStore } from '@/stores/lov/statutAction';
import axios from 'axios';
import ErrorMessage from "@/components/ErrorMessage.vue";
import { useActionStore } from '@/stores/action';


const route = useRoute();
const gestionAction_id = route.params.gestionAction_id;

const userStore = useUserStore();
const { users } = storeToRefs(userStore);
const { getAllUser } = userStore;

const newsStore = useNewsStore();
const { gestionAction } = storeToRefs(newsStore);
const { getOneNewsForAction } = newsStore;

const actionStore = useActionStore();
const { news } = storeToRefs(actionStore);
const { getNewsDTO } = actionStore;

const statutActionStore = useStatutActionStore();
const { statutsAction } = storeToRefs(statutActionStore);
const { getAllStatutAction } = statutActionStore;

const form = reactive({
   action_realiser: "",
   deadline: "",
   news_id: "",  // Initialize as an empty string
   statutAction: [],
   assign: ""
})

const errors = reactive({
   errors: [],
});

onMounted(async () => {
   await getNewsDTO();
   await getAllStatutAction();
   users;
   getAllUser();

   if (gestionAction_id) {
      await getOneNewsForAction(gestionAction_id);
      initializeFormData();
   }
});

function initializeFormData() {
   const action = newsStore.gestionAction;
   form.id = action.id;
   form.action_realiser = action.action_realiser;
   form.deadline = action.deadline;
   form.closeAction = action.closeAction;
   form.news_id = action.news[0].id;  // Initialize form.news_id with the news ID
   form.statutAction = action.statutAction ? [action.statutAction] : [];
   form.assign = action.assign
}

function addItem(event, targetArray) {
   const itemValue = event.target.value;
   if (itemValue && !targetArray.some(item => item.title === itemValue)) {
      const selectedStatutAction = statutsAction.value.find(statutAction => statutAction.title === itemValue);
      if (selectedStatutAction) {
         targetArray.push(selectedStatutAction);
      }
   }
   event.target.value = ''; // Réinitialiser la sélection du <select>
}

function removeItem(itemValue, targetArray) {
   const index = targetArray.findIndex(item => item.title === itemValue);
   if (index > -1) {
      targetArray.splice(index, 1);
   }
}

const termStatutAction = ref(null);
const filteredStatutsAction = computed(() => {
   if (!termStatutAction.value) {
      return statutsAction.value;
   } else {
      return statutsAction.value.filter(statutAction =>
         statutAction.title.toLowerCase().includes(termStatutAction.value.toLowerCase())
      );
   }
});

const onSubmitEdit = async () => {
   errors.errors = [];

   await axios.put("/gestionActions/" + gestionAction_id, {
      action_realiser: form.action_realiser,
      deadline: form.deadline,
      news_id: form.news_id,
      statutAction: form.statutAction.map(statutAction => statutAction.title),
      assign: form.assign,
   })
      .then((response) => {
         console.log(response);
         if (response.status === 200) {
            Swal.fire({
               icon: "success",
               title: "Succès",
               text: "Action Modifiée",
               showConfirmButton: false,
               allowOutsideClick: false,
               timer: 2000,
            }).then((result) => {
               if (result) {
                  window.location.href = "/action"
               }
            })
         }
      })
      .catch(error => {
         console.log(error);
         if (error.response) {
            if (error.response.status === 409) {
               Swal.fire({
                  icon: "error",
                  title: "Erreur",
                  text: "Cette actualité est déjà assignée à une autre action.",
                  showConfirmButton: true,
               });
            } else if (error.response.status === 403) {
               errors.errors.push(error.response.data.message);
            } else if (form.statutAction) {
               errors.errors.push("Veuillez choisir le statut de l'ACTION !");
               return;
            } else if (!form.news_id) {
               errors.errors.push("Veuillez choisir une ACTUALITE !");
               return; // Ne pas continuer si la validation échoue
            } else {
               errors.errors.push("Une erreur inattendue s'est produite.");
            }
         }
      });
}

onBeforeRouteLeave((to, from, next) => {
  // Afficher une alerte de confirmation à l'utilisateur
  Swal.fire({
    title: 'Êtes-vous sûr ?',
    text: "Vous allez quitter la page et les modifications seront perdues.",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Oui, quitter',
    cancelButtonText: 'Annuler'
  }).then((result) => {
    if (result.isConfirmed) {
      // Si l'utilisateur confirme, recharger la page suivante
      window.location.href = to.fullPath;
    } else {
      // Sinon, rester sur la page actuelle
      next(false);
    }
  });
});
</script>



<template>
   <div class="content">

      <nav class="breadcrumbs">
         <div class="breadcrumbs-content">
            <span>
               <a href="/news">Actualité</a>
            </span>
            <span class="breadcrumbs-unicode"> ▸ </span>
            <span>
               <a href="/action">Action</a>
            </span>
            <span class="breadcrumbs-unicode"> ▸ </span>
            <span><small>Modification</small></span>
         </div>
      </nav>
      <div class="content_form">
         <h1 v-if="gestionAction && gestionAction.news">Modifier l'action de l'actualité : {{
            gestionAction.news[0].title }}</h1>

         <div class="form-wrapper">
            <form @submit.prevent="onSubmitEdit" method="post" class="news-form">

               <div class="display">
                  <!-- Choisir une news à modifier -->
                  <div class="liste_deroulante">
                     <h3>Garder l'actualité</h3>
                     <select v-model="form.news_id" required>
                        <option v-if="gestionAction.news && gestionAction.news.length" :value="gestionAction.news[0].id"
                           selected disabled>
                           {{ gestionAction.news[0].title.length > 50 ? gestionAction.news[0].title.substring(0, 50) +
                              '...' : gestionAction.news[0].title }}
                        </option>
                        <option v-for="item in news" :key="item.id" :value="item.id">
                           {{ item.title.length > 50 ? item.title.substring(0, 50) + '...' : item.title }}
                        </option>
                     </select>
                  </div>

                  <!-- USER -->
                 <div class="liste_deroulante">
                     <div>
                        <h3>* Attribuer l'action</h3>
                        <div class="btn_select">
                           <select v-model="form.assign" required>
                              <option v-if="gestionAction.assign" disabled :value=gestionAction.assign selected>{{ gestionAction.assign.lastname }}</option>
                              <option v-for="user in users" :key="user.id" :value="user.id">
                                 {{ user.lastname }}
                              </option>
                           </select>
                        </div>
                     </div>
                  </div>

                  <!-- Deadline -->
                  <div class="input-group">
                     <label for="deadline">
                        <h3>* Deadline</h3>
                     </label>
                     <input v-model="form.deadline" type="date" id="deadline" name="deadline" required>
                  </div>

                  <!-- StatutAction -->
                  <div class="liste_deroulante">
                     <div>
                        <h3>* Les Statuts</h3>
                        <div class="btn_select">
                           <select @change="event => addItem(event, form.statutAction)" :value="''">
                              <option disabled value="" selected>--- Sélectionnez un Statut ---</option>
                              <option v-for="statutAction in filteredStatutsAction" :key="statutAction.id"
                                 :value="statutAction.title">
                                 {{ statutAction.title }}
                              </option>
                           </select>
                        </div>
                     </div>

                     <div class="selected-lovs">
                        <div v-for="statutAction in form.statutAction" :key="statutAction.id" class="selected-lov"
                           @click="() => removeItem(statutAction.title, form.statutAction)">
                           <div class="button">
                              <div class="qube">
                                 <div class="front">Supprimer!</div>
                                 <div class="back">{{ statutAction.title }}</div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>

               </div>

               <!-- Action à réaliser -->
               <div class="input-group">
                  <label for="action_realiser">
                     <h3>* Description</h3>
                  </label>
                  <textarea v-model="form.action_realiser" id="action_realiser" name="action_realiser" rows="9"
                     cols="50" placeholder="Action à réaliser de l'actualité..." required></textarea>
               </div>

               <div class="form-actions">
                  <button type="submit" class="submit-btn">Modifier Action</button>
               </div>
               <ErrorMessage v-if="errors.errors.length" :errors="errors.errors" />

            </form>
         </div>
      </div>
   </div>
</template>


<style scoped lang="scss">
.content {
   margin: 100px 0;
   min-height: 100vh;
}

.content_form {
   max-width: 1200px;
   margin: auto;
}

.submit-btn {
   @include button;
}


.form-wrapper {
   padding: 40px;
   background-color: #e7eff3;
   border-radius: 8px;
   box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.219);
}

.news-form {
   display: flex;
   flex-wrap: wrap;
   gap: 20px;
   justify-content: space-around;
}

.form-section {
   flex: 1;
   width: 320px;
}


.date_group {
   display: flex;
   justify-content: space-evenly;
   gap: 20px;
   flex-wrap: wrap;
}


.form-actions {
   width: 100%;
   display: flex;
   justify-content: center;
   padding-top: 20px;
}


.liste_deroulante {
   margin-bottom: 30px;

   h3 {
      margin-bottom: 15px;
   }

   .btn_select {
      display: flex;
      justify-content: center;
   }

   select {
      width: 330px;
      padding: 7px;
      text-transform: capitalize;
      border: none;
      box-shadow: 0px 0px 5px 0px rgba(51, 51, 51, 0.452);
      cursor: pointer;
   }

   option {
      padding: 5px;
      cursor: pointer;
   }

   // button style flip //

   // VARIABLE
   $buttonHeight: 70px;

   .centered {
      position: relative;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
   }

   .full {
      width: 100%;
      height: 100%;
   }

   .button {
      margin: 10px 0;
      @extend .centered;
      perspective: 1800px;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;

      .qube {
         position: relative;
         height: 50px;
         width: 310px;
         perspective: 1800px;
         display: flex;
         align-items: center;
         justify-content: center;

         .front,
         .back {
            padding: 20px;
            backface-visibility: hidden;
            transform-style: preserve-3d;
            transform: rotateX(-90deg);
            transition: transform 0.3s ease;
            position: absolute;
            color: white;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
         }

         .front {
            background-color: red;
            width: 310px;
            height: 70px;

            &::before {
               @extend .centered;
               @extend .full;
               display: block;
               z-index: -1;

            }

            &::after {
               background: white;
               content: '';
               height: 155px;
               left: -75px;
               opacity: .4;
               position: absolute;
               top: -50px;
               transform: rotate(35deg);
               transition: all 0.3s cubic-bezier(0.19, 1, 0.22, 1);
               width: $buttonHeight - 10px;
               z-index: 1;
            }
         }

         .back {
            background-color: $blue_2;
            color: white;
            transform: rotateX(0deg) translateZ($buttonHeight);
            width: 100%;
            height: 70px;
         }

         &:hover {
            cursor: pointer;

            .front {
               transform: rotateX(0deg) translateZ(calc($buttonHeight / 2));
            }

            .back {
               transform: rotateX(90deg) translateZ(calc($buttonHeight / 2));
            }
         }
      }

      &:active {

         .front {
            border-radius: 0;
            background-color: transparent;
            height: 70px;
            color: $black;

            &::before {
               background-color: red;
               transition: all 0.3s cubic-bezier(0.19, 1, 0.22, 1);
               height: 70px;
            }

            &::after {
               background-color: red;
               left: 120%;
               transition: all 0.3s cubic-bezier(0.19, 1, 0.22, 1);
            }
         }
      }
   }
}
</style>