<script></script>

<template>

    <footer>
        <div class="content_footer">
            <div>
                <p>Intelldata Version 1.0.0 </p>
            </div>
        </div>
    </footer>

</template>

<style scoped lang="scss">
//
footer {
    padding-top: 100px;
    background-color: #faf9f8;
    padding: 20px;
    text-align: center;
    bottom: 0;
    width: 100%;
    height: 60px;
    color: #6c757d;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 1px solid #e9ecef;
    box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.05);
}
</style>