<script>
export default {
  name: "HandlePagination",
  props: {
    maxVisibleButtons: {
      type: Number,
      required: false,
      default: 3,
    },
    totalPages: {
      type: Number,
      required: true,
    },
    itemsPerPage: {
      type: Number,
      required: true,
    },
    currentPage: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    startPage() {
      // When on the first page
      if (this.currentPage === 1) {
        return 1;
      }

      // When on the last page
      if (this.currentPage === this.totalPages && this.totalPages > 3) {
        return this.totalPages - this.maxVisibleButtons;
      }

      // When inbetween
      return this.currentPage - 1;
    },
    hasNews() {
      return this.totalPages > 0;
    },
    pages() {
      const range = [];

      for (
        let i = this.startPage;
        i <=
        Math.min(this.startPage + this.maxVisibleButtons - 1, this.totalPages);
        i++
      ) {
        range.push({
          name: i,
          isDisabled: i === this.currentPage,
        });
      }
      return range;
    },
    isInFirstPage() {
      return this.currentPage === 1;
    },
    isInLastPage() {
      return this.currentPage === this.totalPages;
    },
  },
  methods: {
    onClickFirstPage() {
      // Afin d’informer le parent, nous utiliserons le $emit Méthode pour émettre un événement avec la page cliquée.
      this.$emit("pagechanged", 1);
    },
    onClickPreviousPage() {
      this.$emit("pagechanged", this.currentPage - 1);
    },
    onClickPage(page) {
      this.$emit("pagechanged", page);
    },
    onClickNextPage() {
      this.$emit("pagechanged", this.currentPage + 1);
    },
    onClickLastPage() {
      this.$emit("pagechanged", this.totalPages);
    },
    isPageActive(page) {
      return this.currentPage === page;
    },
  },
};
</script>

<template>
  <div class="pagination_content">
    <ul class="pagination">
      <li class="pagination-item first">
        <button @click="onClickFirstPage" :disabled="isInFirstPage || !hasNews" type="button">
          Première
        </button>
      </li>

      <li class="pagination-item">
        <button class="precedent" @click="onClickPreviousPage" :hidden="isInFirstPage" type="button">
          Précédente
        </button>
      </li>

      <!-- Visible Buttons Start -->

      <li class="pagination-item number" v-for="page in pages" :key="page.name">
        <button @click="onClickPage(page.name)" type="button" :disabled="page.isDisabled">
          {{ page.name }}
        </button>
      </li>

      <!-- Visible Buttons End -->

      <li class="pagination-item">
        <button @click="onClickNextPage" :hidden="isInLastPage || !hasNews" class="suivant" type="button">
          Suivante
        </button>
      </li>

      <li class="pagination-item last">
        <button @click="onClickLastPage" :disabled="isInLastPage || !hasNews" type="button">
          Dernière ({{ totalPages }})
        </button>
      </li>
    </ul>
  </div>
</template>


<style lang="scss" scoped>
.pagination_content {
  text-align: center;
  margin: 40px 0;

}

.pagination {
  list-style-type: none;
  margin: 0;
}

.pagination-item {
  display: inline-block;

  button {
    border: 1px solid rgba(0, 0, 0, 0.212);
    padding: 10px 25px;
    background-color: $blue_1;
    color: white;
    cursor: pointer;

    &:not(:disabled):hover {
      filter: brightness(1.2);
      color: white;
    }
  }
}

button:disabled {
  color: white;
  background-color: $primary;
  cursor: default;
}

@media (max-width: 655px) {
  .pagination-item {
    button {
      padding: 7px 12px;
      font-size: 12px;
    }

  }
  .number {
    display: none;
    
  }
}
</style>
