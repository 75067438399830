<script setup>
import { onMounted, reactive, ref, computed } from 'vue';
import Swal from "sweetalert2";
import { storeToRefs } from 'pinia';
import { useUserStore } from '@/stores/user';
import { useActionStore } from '@/stores/action';
import { useStatutActionStore } from '@/stores/lov/statutAction';
import axios from 'axios';
import ErrorMessage from "@/components/ErrorMessage.vue";

const userStore = useUserStore();
const { users } = storeToRefs(userStore);
const { getAllUser } = userStore;

const actionStore = useActionStore();
const { news } = storeToRefs(actionStore);
const { getNewsDTO } = actionStore;


const statutActionStore = useStatutActionStore();
const { statutsAction } = storeToRefs(statutActionStore);
const { getAllStatutAction } = statutActionStore;

onMounted(() => {
   users;
   getAllUser();
   getAllStatutAction();
   getNewsDTO();
   news;
})

const action_realiser = ref("");
const deadline = ref(null);
const closeAction = (null);
const news_id = ref(null);
const selectedStatutsAction = ref([]);
const assign = ref("");


/************* Méthode générique des lov pour ajouter et les supprimer **************/
function addItem(event, targetArray) {
   const itemValue = event.target.value;
   if (itemValue && !targetArray.includes(itemValue)) {
      targetArray.push(itemValue);
   }
   event.target.value = ''; // Réinitialiser la sélection du <select>
}

function removeItem(itemValue, targetArray) {
   const index = targetArray.indexOf(itemValue);
   if (index > -1) {
      targetArray.splice(index, 1);
   }
}
/////////////////////////////////////

/******* Charge et affiche dynamiquement les STATUTS des ACTIONS ******/
const termStatutAction = ref(null);
const filteredStatutsAction = computed(() => {
   if (!termStatutAction.value) {
      return statutsAction.value;
   } else {
      return statutsAction.value.filter(statutAction =>
         statutAction.title.toLowerCase().includes(termStatutAction.value.toLowerCase())
      );
   }
});
/////////////////////////////////////////////


const form = reactive({
   action_realiser: action_realiser.value,
   deadline: deadline.value,
   // closeAction: closeAction.value,
   news_id: news_id.value,
   statutAction: selectedStatutsAction.value,
   assign: assign.value
})

const errors = reactive({
   errors: [],
});

/******** AJOUTER GestionAction ********/
const onSubmitAjout = async () => {
   errors.errors = [];

   // Vérifiez si le champ form.news_id est vide
   if (!form.news_id) {
      errors.errors.push("Veuillez choisir une ACTUALITE !");
      return; // Ne pas continuer si la validation échoue
   }
   if (!form.statutAction) {
      errors.errors.push("Veuillez choisir le statut de l'ACTION !");
      return;
   }

   await axios.post("/gestionActions", form)
      .then((response) => {
         console.log(response);
         if (response.status === 201) {
            Swal.fire({
               icon: "success",
               title: "Succès",
               text: "Action Ajouté",
               showConfirmButton: false,
               allowOutsideClick: false,
               timer: 2000,
            }).then((result) => {
               if (result) {
                  window.location.href = "/action"
               }
            })
         }
      })
      .catch(error => {
         console.log(error);
         if (error.response) {
            if (error.response.status === 409) {
               // Afficher un message spécifique pour le conflit
               Swal.fire({
                  icon: "error",
                  title: "Erreur",
                  text: "Cette actualité est déjà assignée à une autre action.",
                  showConfirmButton: true,
               });
            } else if (error.response.status === 403) {
               errors.errors.push(error.response.data.message);
            } else {
               // Pour les autres types d'erreurs, vous pourriez les ajouter à votre tableau d'erreurs ou les gérer ici.
               errors.errors.push("Une erreur inattendue s'est produite.");
            }
         }
      });
}

</script>

<template>
   <div class="content">

      <nav class="breadcrumbs">
         <div class="breadcrumbs-content">
            <span>
               <a href="/news">Actualité</a>
            </span>
            <span class="breadcrumbs-unicode"> ▸ </span>
            <span>
               <a href="/action">Action</a>
            </span>
            <span class="breadcrumbs-unicode"> ▸ </span>
            <span><small>Ajout</small></span>
         </div>
      </nav>

      <div class="content_form">
         <h1>Ajouter une action</h1>


         <div class="form-wrapper">
            <form @submit.prevent="onSubmitAjout" method="post" class="news-form">
               <div class="display">
                  <!-- Choisir une nws à modifier-->
                  <div class="liste_deroulante">
                     <h3>* Choisir une actualité</h3>
                     <select v-model="form.news_id" required>
                        <option disabled :value="news_id">--- Sélectionnez une Actualité ---</option>
                        <option v-for="item in news" :key="item.id" :value="item.id" :title="item.title">
                           {{ item.title.length > 50 ? item.title.substring(0, 50) + '...' : item.title }}
                        </option>
                     </select>
                  </div>

                  <!-- USER -->
                  <div class="liste_deroulante">
                     <div>
                        <h3>* Attribuer l'action</h3>
                        <div class="btn_select">
                           <select v-model="form.assign" required>
                              <option disabled value="" selected>--- Sélectionnez un utilisateur ---</option>
                              <option v-for="user in users" :key="user.id" :value="user.id">
                                 {{ user.lastname }}
                              </option>
                           </select>
                        </div>
                     </div>
                  </div>

                  <!-- StatutAction -->
                  <div class="liste_deroulante">

                     <div>
                        <h3>* Les Statuts des Actions</h3>
                        <div class="btn_select">
                           <select @change="event => addItem(event, form.statutAction)" :value="''">
                              <option disabled value="" selected>--- Sélectionnez un Statut ---</option>
                              <option v-for="statutAction in filteredStatutsAction" :key="statutAction.title"
                                 :value="statutAction.title">
                                 {{ statutAction.title }}
                              </option>
                           </select>
                        </div>
                     </div>

                     <div class="selected-lovs">
                        <div v-for="actionTitle in selectedStatutsAction" :key="actionTitle" class="selected-lov"
                           @click="() => removeItem(actionTitle, selectedStatutsAction)">
                           <div class="button">
                              <div class="qube">
                                 <div class="front">Supprimer!</div>
                                 <div class="back">{{ actionTitle }}</div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>

                  <!-- Deadline -->
                  <div class="input-group ">
                     <label for="deadline">
                        <h3>* Deadline</h3>
                     </label>
                     <input v-model="form.deadline" type="date" id="deadline" name="deadline" required>
                  </div>

               </div>

               <!-- Action à réaliser-->
               <div class="input-group">
                  <label for="action_realiser">
                     <h3>* Description</h3>
                  </label>
                  <textarea v-model="form.action_realiser" id="action_realiser" name="action_realiser" rows="9"
                     cols="50" placeholder="Action à réaliser de l'actualité..." required></textarea>
               </div>

               <div class="form-actions">
                  <button type="submit" class="submit-btn">Ajouter Action</button>
               </div>
               <ErrorMessage v-if="errors.errors.length" :errors="errors.errors" />

            </form>
         </div>
      </div>
   </div>
</template>

<style scoped lang="scss">
.content {
   margin: 100px 0;
   min-height: 100vh;
}

.content_form {
   max-width: 1200px;
   margin: auto;
}

.submit-btn {
   @include button;
}


.form-wrapper {
   padding: 40px;
   background-color: #e7eff3;
   border-radius: 8px;
   box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.219);
}

.news-form {
   display: flex;
   flex-wrap: wrap;
   gap: 20px;
   justify-content: space-around;
}

.form-section {
   flex: 1;
   width: 320px;
}


.date_group {
   display: flex;
   justify-content: space-evenly;
   gap: 20px;
   flex-wrap: wrap;
}

.form-actions {
   width: 100%;
   display: flex;
   justify-content: center;
   padding-top: 20px;
}
</style>