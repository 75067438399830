<script setup>
import { ref, onMounted } from 'vue';
import { RouterView, useRouter } from 'vue-router'
import Spinner from './components/partial/Spinner.vue';


const loading = ref(false);
const router = useRouter();

onMounted(() => {
  router.beforeEach(() => {
    loading.value = true;
  });

  router.afterEach(() => {
    loading.value = false;
  });

  router.onError(() => {
    loading.value = false; // Make sure to handle errors
  });
});

</script>

<template>
  <nav>
  </nav>
    <div class="body">
      <div class="content">
        <Spinner v-if="loading" />
        <RouterView />
      </div>
    </div>

</template>

<style scoped lang="scss">
//
.body {
  overflow: hidden;
  background-color: #f1f5ff70;
}
</style>
