import { createApp } from 'vue';
import { createPinia } from 'pinia';
import { useUserStore } from './stores/user';

import App from './App.vue';
import router from './router';

import axios from 'axios';

// Accéder à la variable d'environnement
const baseUrl = import.meta.env.VITE_APP_BASE_URL;
axios.defaults.baseURL = `${baseUrl}/api/`;

console.log('Base URL:', import.meta.env.VITE_APP_BASE_URL);

axios.interceptors.request.use(
  (request) => {
    console.log("request: ", request);
    const user = useUserStore();
    if (user.loggedIn) {
      request.headers["Authorization"] = "Bearer " + user.getToken;
    }
    return request;
  },
  (error) => {
    return Promise.reject(error);
  }
); 

axios.interceptors.response.use(
  (response) => {
    console.log("response: ", response);
    return response;
  },
  (error) => {
    console.log("tesssttt : ", error)
    if (error.response.status === 404) {
      console.log('erreur : ', error);
      //window.location.href = "/not-found"; // Rediriger vers la page d'erreur 404
    } else if (error.response.status === 401 && error.response.data.message === "Expired JWT Token") {
      router.push({ name: 'connexion' });
    } 
    return Promise.reject(error);
  }
);


// Écouter l'événement popstate pour recharger la page lors de la navigation arrière/avant
window.addEventListener('popstate', () => {
  window.location.reload();
});

const app = createApp(App)

app.use(createPinia())
app.use(router)
app.mount('#app')
