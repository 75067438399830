<script setup>
import { onMounted, ref } from "vue";
import { storeToRefs } from "pinia";
import { useActionStore } from "@/stores/action";
import { useStatutActionStore } from "@/stores/lov/statutAction";
import HandlePagination from "@/components/HandlePagination.vue";
import FormSearch from "@/components/FormSearch.vue";



const actionStore = useActionStore();
const { gestionAction, countItems, limiteItems, newsProduct, currentPage, gestionAction_id, sortBy, sortOrder } = storeToRefs(actionStore);
const { getNewsForAction } = actionStore;

const statutActionStore = useStatutActionStore();
const { statutAction } = storeToRefs(statutActionStore);
const { getAllStatutAction } = statutActionStore;

const searchNews = ref('');
const searchActionRealiseer = ref('');
const searchCreatBy = ref('');
const searchUpdatBy = ref('');
const searchDeadline = ref('');
const searchStatutAction = ref('');
const searchCloseAt = ref('');


/***** Methode pour les recherches par titre ******/
function handleSearch({ type, value }) {
    if (type === 'newsTitle') {
        searchNews.value = value;
    } else if (type === 'action_realiser') {
        searchActionRealiseer.value = value;
    } else if (type === 'createdBy') {
        searchCreatBy.value = value;
    } else if (type === 'updatedBy') {
        searchUpdatBy.value = value;
    } else if (type === 'statutAction') {
        searchStatutAction.value = value;
    }
    currentPage.value = 1; // remmettre à la 1er page
    getNewsForAction(searchNews.value, searchActionRealiseer.value, searchCreatBy.value, searchUpdatBy.value, searchStatutAction.value, sortBy.value, sortOrder.value);
}
///////////////////////////////

/***** Filtrer oui/non par actualité/Produit ******/
const showNewsProductTrue = ref(false);
const showNewsProductFalse = ref(false);

const handleFilter = async () => {
    if (showNewsProductTrue.value && !showNewsProductFalse.value) {
        await getNewsForAction(searchNews.value, searchActionRealiseer.value, searchCreatBy.value, searchUpdatBy.value, sortBy.value, sortOrder.value, searchStatutAction.value, newsProduct.value = 1);

    } else if (!showNewsProductTrue.value && showNewsProductFalse.value) {
        await getNewsForAction(searchNews.value, searchActionRealiseer.value, searchCreatBy.value, searchUpdatBy.value, sortBy.value, sortOrder.value, searchStatutAction.value, newsProduct.value = 0);

    } else {
        await getNewsForAction(searchNews.value, searchActionRealiseer.value, searchCreatBy.value, searchUpdatBy.value, sortBy.value, sortOrder.value, searchStatutAction.value, newsProduct.value = null);

    }

};
/////////////////////////////////////

/***** Afficher les news en cours ou clos ******/
const filterByAction = () => {
    currentPage.value = 1; // Réinitialiser la pagination à la première page
    getNewsForAction(searchNews.value, searchActionRealiseer.value, searchCreatBy.value, searchUpdatBy.value, searchStatutAction.value, sortBy.value, sortOrder.value);
};
//////////////////////////////////////

/******* Le trie par ordre et type ******/
const isDeadlineSorted = ref(false);
const handleSort = (field) => {
    if (sortBy.value === field) {
        sortOrder.value = sortOrder.value === 'ASC' ? 'DESC' : 'ASC'; // Toggle sort order
        isDeadlineSorted.value = !isDeadlineSorted.value;
    } else {
        sortBy.value = field;
        sortOrder.value = 'ASC'; // Default to ascending order
        isDeadlineSorted.value = false;
    }
    currentPage.value = 1; // Réinitialiser à la première page
    getNewsForAction(searchNews.value, searchActionRealiseer.value, searchCreatBy.value, searchUpdatBy.value, searchStatutAction.value, sortBy.value, sortOrder.value);
};
//////////////////////////////////////


onMounted(() => {
    getNewsForAction(),
        statutActionStore.getAllStatutAction()
});

/***** Gestion pagination ******/
const itemsPerPage = ref(limiteItems);

const onPageChange = async (page) => {
    currentPage.value = page;
    await getNewsForAction(searchNews.value, searchActionRealiseer.value, searchCreatBy.value, searchUpdatBy.value, searchStatutAction.value, sortBy.value, sortOrder.value, page);

};
/////////////////////////////////


</script>

<template>
    <div class="table_body">
            <table>
                <thead>
                    <tr class="title-row">
                        <th>Id</th>
                        <th>Actualité</th>
                        <th>Actualité<br />Produit</th>
                        <th>Action à réaliser</th>
                        <th>Crée Par</th>
                        <th>Modifié par</th>
                        <th>Assigné</th>
                        <th>Deadline</th>
                        <th>Statut</th>
                        <th>Date de cloture</th>
                        <th>V/M/D</th>
                    </tr>
                </thead>
                <thead class="recherche">
                    <tr class="first-head-row">
                        <td class="sort_deadline">
                            <div @click="handleSort('id')" :class="{ active: isDeadlineSorted }">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                    fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                    stroke-linejoin="round"
                                    class="icon icon-tabler icons-tabler-outline icon-tabler-arrows-up-down">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                    <path d="M7 3l0 18" />
                                    <path d="M10 6l-3 -3l-3 3" />
                                    <path d="M20 18l-3 3l-3 -3" />
                                    <path d="M17 21l0 -18" />
                                </svg>
                            </div>
                        </td>
                        <td>
                            <FormSearch @search="handleSearch" searchType="newsTitle"
                                placeholder="Recherche par News..." />
                            <div v-if="searchNews">
                                <small>Recherche pour : </small> {{ searchNews }}
                            </div>
                        </td>
                        <td>
                            <div class="newsProduct">
                                <div class="newsProduct__true">
                                    <input type="checkbox" id="true" v-model="showNewsProductTrue"
                                        @change="handleFilter" />
                                    <label for="true">Oui</label>
                                </div>
                                <div class="newsProduct__false">
                                    <input type="checkbox" id="false" v-model="showNewsProductFalse"
                                        @change="handleFilter" />
                                    <label for="false">Non</label>
                                </div>
                            </div>
                        </td>

                        <td>
                            <FormSearch @search="handleSearch" searchType="action_realiser"
                                placeholder="Recherche par News..." />
                            <div v-if="searchActionRealiseer">
                                <small>Recherche pour : </small> {{ searchActionRealiseer }}
                            </div>
                        </td>

                        <td>
                            <FormSearch @search="handleSearch" searchType="createdBy"
                                placeholder="Recherche par News..." />
                            <div v-if="searchCreatBy">
                                <small>Recherche pour : </small> {{ searchCreatBy }}
                            </div>
                        </td>
                        <td>
                            <FormSearch @search="handleSearch" searchType="updatedBy" placeholder="Recherche..." />
                            <div v-if="searchUpdatBy">
                                <small>Recherche pour : </small> {{ searchUpdatBy }}
                            </div>
                        </td>
                        <td></td>
                        <td class="sort_deadline">
                            <div @click="handleSort('deadline')" :class="{ active: isDeadlineSorted }">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                    fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                    stroke-linejoin="round"
                                    class="icon icon-tabler icons-tabler-outline icon-tabler-arrows-up-down">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                    <path d="M7 3l0 18" />
                                    <path d="M10 6l-3 -3l-3 3" />
                                    <path d="M20 18l-3 3l-3 -3" />
                                    <path d="M17 21l0 -18" />
                                </svg>
                            </div>
                        </td>
                        <td>
                            <select v-model="searchStatutAction" @change="filterByAction">
                                <option value="">-- All Status --</option>
                                <option value="en cours">En cours</option>
                                <option value="clos">Clos</option>
                                <option value="na">N/A</option>
                            </select>
                        </td>
                        <td class="sort_deadline">
                            <div @click="handleSort('closeAction')" :class="{ active: isDeadlineSorted }">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                    fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                    stroke-linejoin="round"
                                    class="icon icon-tabler icons-tabler-outline icon-tabler-arrows-up-down">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                    <path d="M7 3l0 18" />
                                    <path d="M10 6l-3 -3l-3 3" />
                                    <path d="M20 18l-3 3l-3 -3" />
                                    <path d="M17 21l0 -18" />
                                </svg>
                            </div>
                        </td>
                        <td></td>

                    </tr>
                </thead>
                <tbody>
                    <tr v-for="item in gestionAction" :key="item.id">
                        <td>{{ item.id }}</td>
                        <td><a :href="item.news[0].url" target="_blank">{{ item.news[0].title }}</a></td>
                        <td v-if="item.news[0].newsProduct == true">oui</td>
                        <td v-else>non</td>
                        <td>{{ item.action_realiser }}</td>
                        <td>{{ item.createdBy.lastname }}</td>
                        <td v-if="item.updatedBy">{{ item.updatedBy.lastname }}</td>
                        <td v-else> / </td>
                        <td v-if="item.assign">{{ item.assign.lastname }}</td>
                        <td v-else>/</td>
                        <td>{{ item.deadline }}</td>
                        <td>{{ item.statutAction.title }}</td>
                        <td>{{ item.closeAction }}</td>
                        <td>
                            <div class="btn_action">
                                <RouterLink :to="{ name: 'editGestionAction', params: { gestionAction_id: item.id } }"
                                    title="Modifier" class="edit">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-edit"
                                        width="20" height="20" viewBox="0 0 24 24" stroke-width="2"
                                        stroke="currentColor" fill="none" stroke-linecap="round"
                                        stroke-linejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                        <path d="M7 7h-1a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-1" />
                                        <path
                                            d="M20.385 6.585a2.1 2.1 0 0 0 -2.97 -2.97l-8.415 8.385v3h3l8.385 -8.415z" />
                                        <path d="M16 5l3 3" />
                                    </svg>
                                </RouterLink>
                            </div>
                        </td>
                    </tr>

                </tbody>
            </table>
        </div>
        <div class="pagination">
            <HandlePagination :totalPages="Math.ceil(countItems / itemsPerPage)" :itemsPerPage="itemsPerPage"
                :currentPage="currentPage" @pagechanged="onPageChange" />
        </div>
</template>

<style scoped lang="scss">
.content {
    margin: 150px auto;
    min-height: 100vh;
    width: 1400px;
}

h1 {
    text-align: center;
}

/******** TABLE ********/
.table_body {
    @include table;
    margin: auto;
}

/******** BOUTON *******/
.btn_actualite {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1.5rem;
    margin: 2.5rem 2rem;
    flex-wrap: wrap;

    .btn {
        button {
            display: flex;
            justify-content: center;
            display: flex;
            align-items: center;
            @include button;
            gap: 1rem;
        }
    }
}

.newsProduct {
    display: flex;
    justify-content: space-around;

    &__true {
        margin-right: 15px;
    }

    label {
        margin-left: 5px;
    }

    input,
    label {
        cursor: pointer;

        &:hover {
            box-shadow: 1px 1px 10px 1px rgba(39, 39, 211, 0.192);
        }
    }
}

.sort_deadline {
    div {
        align-items: center;
        cursor: pointer;
    }
}
</style>