import { defineStore } from "pinia";
import axios from "axios";
import Swal from "sweetalert2";

export const useStatutNewsStore = defineStore({
  id: "statutNews",
  state: () => ({
    id: null,
    errors: [],
    statutNews: [],
    statutsNews: [],
  }),

  getters: {
    getErrors: (state) => state.errors,
  },

  actions: {
    async getAllStatutNews() {
      this.statutsNews = [];
      this.loading = true;
      await axios.get("/statutNews").then((response) => {
        console.log(response);
        this.loading = false;
        if (response.status == 200) {
          this.statutsNews = response.data;
        }
      });
    },

    async getStatutNews() {
      this.statutNews = [];
      this.loading = true;
      const statutNewsId = localStorage.getItem("statutNewsId");
      await axios
        .get("/statutNews/" + statutNewsId)
        .then((response) => {
          console.log(response);
          this.loading = false;
          if (response.status == 200) {
            this.statutNews = response.data;
          }
        })
        .catch((error) => {
          console.log(error);
          if (error.response.status == 404) {
            this.$router.push({ name: "not-found" });
          }
        });
    },

    async deleteStatutNews(id) {
      await axios.delete("/statutNews/" + id).then((response) => {
        console.log(response);
        if (response.status == 204) {
          Swal.fire({
            icon: "success",
            title: "statut Supprimé !",
            allowOutsideClick: false,
          }).then(async (result) => {
            if (result.value) {
              await this.getAllStatutNews();
            }
          });
        }
      });
    },
  },
});
