<script setup>
import { ref } from 'vue';

const emit = defineEmits(['search']);
const props = defineProps({
   name: String,
   placeholder: String,
   initialValue: String,
   searchType: String 
});

const searchTerm = ref(props.initialValue || '');

function submitForm(e) {
   e.preventDefault();
   // Utiliser searchType pour différencier les types de recherche
   emit('search', { type: props.searchType, value: searchTerm.value });
   // Réinitialiser searchTerm à sa valeur initiale ou à une chaîne vide
   searchTerm.value = props.initialValue || '';
}
</script>

<template>
   <form @submit.prevent="submitForm">
      <div class="search">
         <input v-model="searchTerm" :name="props.name" type="text" :placeholder="props.placeholder"
            aria-label="Search in website" />
         <button type="submit">
            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-search" width="18" height="18"
               viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round"
               stroke-linejoin="round">
               <path stroke="none" d="M0 0h24v24H0z" fill="none" />
               <path d="M10 10m-7 0a7 7 0 1 0 14 0a7 7 0 1 0 -14 0" />
               <path d="M21 21l-6 -6" />
            </svg>
         </button>
      </div>
   </form>
</template>

<style scoped lang="scss">
form {
   @include search;
   min-width: 150px;
}
</style>