<script setup>
import { useUserStore } from "@/stores/user";

const userStore = useUserStore();
const { getTokenDecoded } = userStore;


</script>

<template>
   <nav v-if="userStore.loggedIn">
      <div class="page">
         <div id="nav-container">
            <div id="nav-content" tabindex="0">
               <div class="">
                  <img src="@/assets/logo/intelledata.png" alt="logo de Intelledata" class="image" loading="lazy" />
               </div>
               <ul class="categories_content">
                  <li>
                     <div class="category-link" aria-hidden="true">
                        <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-news" width="24"
                           height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                           stroke-linecap="round" stroke-linejoin="round">
                           <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                           <path
                              d="M16 6h3a1 1 0 0 1 1 1v11a2 2 0 0 1 -4 0v-13a1 1 0 0 0 -1 -1h-10a1 1 0 0 0 -1 1v12a3 3 0 0 0 3 3h11" />
                           <path d="M8 8l4 0" />
                           <path d="M8 12l4 0" />
                           <path d="M8 16l4 0" />
                        </svg>
                        Actualités
                     </div>
                     <ul class="sub-categories">
                        <li><router-link to="/news">
                              <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-list-details"
                                 width="24" height="24" viewBox="0 0 24 24" stroke-width="1.25" stroke="currentColor"
                                 fill="none" stroke-linecap="round" stroke-linejoin="round">
                                 <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                 <path d="M13 5h8" />
                                 <path d="M13 9h5" />
                                 <path d="M13 15h8" />
                                 <path d="M13 19h5" />
                                 <path
                                    d="M3 4m0 1a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v4a1 1 0 0 1 -1 1h-4a1 1 0 0 1 -1 -1z" />
                                 <path
                                    d="M3 14m0 1a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v4a1 1 0 0 1 -1 1h-4a1 1 0 0 1 -1 -1z" />
                              </svg>
                              listes
                           </router-link>
                        </li>
                        <li>
                           <router-link to="/action">
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                 fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                 stroke-linejoin="round"
                                 class="icon icon-tabler icons-tabler-outline icon-tabler-sort-ascending">
                                 <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                 <path d="M4 6l7 0" />
                                 <path d="M4 12l7 0" />
                                 <path d="M4 18l9 0" />
                                 <path d="M15 9l3 -3l3 3" />
                                 <path d="M18 6l0 12" />
                              </svg>
                              Actions
                           </router-link>
                        </li>
                        <li>
                           <router-link to="/export">
                              <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-file-export"
                                 width="24" height="24" viewBox="0 0 24 24" stroke-width="1.25" stroke="currentColor"
                                 fill="none" stroke-linecap="round" stroke-linejoin="round">
                                 <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                 <path d="M14 3v4a1 1 0 0 0 1 1h4" />
                                 <path
                                    d="M11.5 21h-4.5a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v5m-5 6h7m-3 -3l3 3l-3 3" />
                              </svg>
                              Export
                           </router-link></li>
                     </ul>
                  </li>

                  <li>
                     <a href="#" class="category-link" aria-hidden="true">
                        <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-apps" width="24"
                           height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                           stroke-linecap="round" stroke-linejoin="round">
                           <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                           <path d="M4 4m0 1a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v4a1 1 0 0 1 -1 1h-4a1 1 0 0 1 -1 -1z" />
                           <path d="M4 14m0 1a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v4a1 1 0 0 1 -1 1h-4a1 1 0 0 1 -1 -1z" />
                           <path d="M14 14m0 1a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v4a1 1 0 0 1 -1 1h-4a1 1 0 0 1 -1 -1z" />
                           <path d="M14 7l6 0" />
                           <path d="M17 4l0 6" />
                        </svg>
                        Administration
                     </a>
                     <ul class="sub-categories">
                        <li v-if="getTokenDecoded && getTokenDecoded.roles.includes('ROLE_RESPONSABLE')">
                           <router-link to="/administration/user">
                              <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-users"
                                 width="24" height="24" viewBox="0 0 24 24" stroke-width="1.25" stroke="currentColor"
                                 fill="none" stroke-linecap="round" stroke-linejoin="round">
                                 <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                 <path d="M9 7m-4 0a4 4 0 1 0 8 0a4 4 0 1 0 -8 0" />
                                 <path d="M3 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
                                 <path d="M16 3.13a4 4 0 0 1 0 7.75" />
                                 <path d="M21 21v-2a4 4 0 0 0 -3 -3.85" />
                              </svg>
                              Utilisateurs
                           </router-link>
                        </li>
                        <li>
                           <router-link to="/administration/product">
                              <svg xmlns="http://www.w3.org/2000/svg"
                                 class="icon icon-tabler icon-tabler-medicine-syrup" width="24" height="24"
                                 viewBox="0 0 24 24" stroke-width="1.25" stroke="currentColor" fill="none"
                                 stroke-linecap="round" stroke-linejoin="round">
                                 <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                 <path
                                    d="M8 21h8a1 1 0 0 0 1 -1v-10a3 3 0 0 0 -3 -3h-4a3 3 0 0 0 -3 3v10a1 1 0 0 0 1 1z" />
                                 <path d="M10 14h4" />
                                 <path d="M12 12v4" />
                                 <path d="M10 7v-3a1 1 0 0 1 1 -1h2a1 1 0 0 1 1 1v3" />
                              </svg>
                              Produits
                           </router-link>
                        </li>
                        <li>
                           <router-link to="/administration/customer">
                              <svg xmlns="http://www.w3.org/2000/svg"
                                 class="icon icon-tabler icon-tabler-building-hospital" width="24" height="24"
                                 viewBox="0 0 24 24" stroke-width="1.25" stroke="currentColor" fill="none"
                                 stroke-linecap="round" stroke-linejoin="round">
                                 <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                 <path d="M3 21l18 0" />
                                 <path d="M5 21v-16a2 2 0 0 1 2 -2h10a2 2 0 0 1 2 2v16" />
                                 <path d="M9 21v-4a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v4" />
                                 <path d="M10 9l4 0" />
                                 <path d="M12 7l0 4" />
                              </svg>
                              Clients
                           </router-link>
                        </li>
                        <li><router-link to="/administration/lov">
                              <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-checkup-list"
                                 width="24" height="24" viewBox="0 0 24 24" stroke-width="1.25" stroke="currentColor"
                                 fill="none" stroke-linecap="round" stroke-linejoin="round">
                                 <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                 <path
                                    d="M9 5h-2a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-12a2 2 0 0 0 -2 -2h-2" />
                                 <path
                                    d="M9 3m0 2a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v0a2 2 0 0 1 -2 2h-2a2 2 0 0 1 -2 -2z" />
                                 <path d="M9 14h.01" />
                                 <path d="M9 17h.01" />
                                 <path d="M12 16l1 1l3 -3" />
                              </svg>
                              LOV
                           </router-link>
                        </li>
                        <li>
                           <router-link to="/audit">
                              <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-usb"
                                 width="24" height="24" viewBox="0 0 24 24" stroke-width="1.25" stroke="currentColor"
                                 fill="none" stroke-linecap="round" stroke-linejoin="round">
                                 <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                 <path d="M12 19m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" />
                                 <path d="M12 17v-11.5" />
                                 <path d="M7 10v3l5 3" />
                                 <path d="M12 14.5l5 -2v-2.5" />
                                 <path d="M16 10h2v-2h-2z" />
                                 <path d="M7 9m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0" />
                                 <path d="M10 5.5h4l-2 -2.5z" />
                              </svg>
                              Audit Trail
                           </router-link>
                        </li>
                     </ul>
                  </li>

                  <div class="separation"></div>

                  <div v-if="userStore.loggedIn">
                     <li>
                        <router-link to="/dashboard" class="category">
                           <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-user" width="24"
                              height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                              stroke-linecap="round" stroke-linejoin="round">
                              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                              <path d="M8 7a4 4 0 1 0 8 0a4 4 0 0 0 -8 0" />
                              <path d="M6 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
                           </svg>
                           Mon compte
                        </router-link>
                     </li>

                     <li @click.prevent="userStore.logout">
                        <a href="/" class="category">
                           <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-logout"
                              width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor"
                              fill="none" stroke-linecap="round" stroke-linejoin="round">
                              <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                              <path d="M14 8v-2a2 2 0 0 0 -2 -2h-7a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h7a2 2 0 0 0 2 -2v-2">
                              </path>
                              <path d="M9 12h12l-3 -3"></path>
                              <path d="M18 15l3 -3"></path>
                           </svg>
                           Deconnexion
                        </a>
                     </li>
                  </div>
               </ul>
            </div>
         </div>
      </div>
   </nav>
</template>

<style scoped lang="scss">
svg {
   margin-right: 8px;
}

strong {
   font-weight: 600;
}

.page {
   position: absolute;
   top: 0px;
   background: #fdfdfd;
   font-weight: 600;
   letter-spacing: .03em;
}

#nav-container {
   position: fixed;
   height: 100vh;
   width: 100%;
   pointer-events: none;
}

#nav-container .bg {
   position: absolute;
   top: 91px;
   left: 0;
   width: 100%;
   height: calc(100% - 0px);
   transition: .3s;
}

#nav-content {
   padding: 20px 15px;
   width: 220px;
   position: absolute;
   top: 0;
   left: -5px;
   height: calc(100% - 2px);
   background: rgb(255, 255, 255);
   pointer-events: auto;
   overflow-y: auto;
   box-shadow: 2px 2px 5px rgba(155, 155, 155, 0.164);
}



#nav-content li a {
   display: flex;
   align-items: center;
   text-transform: uppercase;
   transition: color .1s;
   font-size: 15px;
   transition: all .2s ease-in-out;

   &:hover {
      transition: all .2s ease-in-out;
      color: $primary;
      padding-left: 0.4rem;
   }
}


a {
   color: currentColor;
   transition: .2s ease-in-out;
}



ul {
   padding: 0;
   list-style: none;
}

.separation {
   width: 80%;
   height: 2px;
   background: $gray-4;
   margin: 20px 0;
}


.categories_content {

   .category-link,
   .category {
      color: $dark_blue;
      margin-bottom: 15px;
      display: flex;


      &+.sub-categories {
         padding-left: 20px;
         margin-bottom: 25px;


      }
   }

   li {
      text-decoration: none;
      color: black;
      cursor: pointer;
      align-items: center;
      margin-bottom: 15px;

   }

   & li:not(.brand) {
      color: $gray-1;
      text-decoration: none;
      transition: all 0.2s;

      &:hover {
         color: $dark-blue;
      }

      &.router-link-exact-active {
         color: white;

         &:hover {
            color: $dark-blue;
         }
      }
   }
}

img {
   width: 100%;
   margin-bottom: 20px;
}
</style>
