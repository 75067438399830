import { defineStore } from "pinia";
import axios from "axios";
import Swal from "sweetalert2";

export const useActionStore = defineStore("action", {
  state: () => {
    const currentDate = new Date();
    const startDate = new Date();
    currentDate.setDate(currentDate.getDate() + 1); // Ajoute un jour à la date actuelle pour afficher les news du jour actuelle
    startDate.setDate(currentDate.getDate() - 7);

    return {
      id: null,
      errors: [],
      news: [],
      gestionAction: [],
      gestionAction_id: null,
      limiteItems: 5,
      currentPage: 1,
      page: null,
      sortOrder: "desc",
      sortBy: "created_at",
      countItems: null,
      disable: 0,
      newsProduct: null,
      startDate: startDate.toISOString().split("T")[0], // Définir la date de début à 7 jours avant la date actuelle
      endDate: currentDate.toISOString().split("T")[0], // Définir la date de fin à la date actuelle
    };
  },

  getters: {
    getErrors: (state) => state.errors,
  },

  actions: {
    async getNewsForAction(
      newsTitle,
      action_realiser,
      createdBy,
      updatedBy,
      statutAction
    ) {
      this.gestionAction = [];
      let params = {
        page: this.currentPage,
        limit: this.limiteItems,
        sortBy: this.sortBy,
        sortOrder: this.sortOrder,
      };

      if (this.newsProduct !== null) {
        params.newsProduct = this.newsProduct;
      }

      if (newsTitle) {
        params.newsTitle = newsTitle;
      }

      if (action_realiser) {
        params.action_realiser = action_realiser;
      }

      if (createdBy) {
        params.createdBy = createdBy;
      }

      if (updatedBy) {
        params.updatedBy = updatedBy;
      }

      if (statutAction) {
        params.statutAction = statutAction;
      }

      try {
        const response = await axios.get("/gestionActions", { params });
        if (response.status === 200) {
          this.gestionAction = response.data.item;
          this.countItems = response.data.countItems;
        }
      } catch (error) {
        console.error(error);
        // Gérer l'erreur ici
      }
    },

    async getOneNewsForAction(gestionAction_id) {
      this.gestionAction = [];

      await axios
        .get("/gestionActions/" + gestionAction_id)
        .then((response) => {
          console.log(response);
          this.loading = false;
          if (response.status == 200) {
            this.gestionAction = response.data;
          }
        })
        .catch((error) => {
          console.log(error);
          if (error.response.status == 404) {
            //this.$router.push({ name: "not-found" });
          }
        });
    },

    async getActionByNews(
      news_id,
      action_realiser,
      createdBy,
      assign,
      updatedBy,
      statutAction
    ) {
      this.gestionAction = [];

      let params = {
        page: this.currentPage,
        limit: this.limiteItems,
        sortBy: this.sortBy,
        sortOrder: this.sortOrder,
      };

      if (action_realiser) {
        params.action_realiser = action_realiser;
      }

      if (createdBy) {
        params.createdBy = createdBy;
      }

      if (assign) {
        params.assign = assign;
      }

      if (updatedBy) {
        params.updatedBy = updatedBy;
      }

      if (statutAction) {
        params.statutAction = statutAction;
      }

      try {
        const response = await axios.get("/actions-by-news/" + news_id, {
          params
        });
        if (response.status === 200) {
          this.gestionAction = response.data.items;
          this.countItems = response.data.countItems;
        }
      } catch (error) {
        console.error(error);
      }
    },

    async getNewsDTO() {
      this.news = [];

      try {
        const response = await axios.get("/newsDTO");
        if (response.status === 200) {
          console.log(response.data);
          this.news = response.data;
          // this.countItems = response.data.countItems;
        }
      } catch (error) {
        console.error(error);
        // Gérer l'erreur ici
      }
    },
  },
});
